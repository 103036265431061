import { isNumber } from 'lodash';
import { perFacadeApi } from 'helpers/api.helper';
import { DropdownOption } from 'models';
import {
  ShadowHealthCourse,
  ShadowHealthCourseSection,
  ShadowHealthCourseSummary,
  SHAssignmentSummaryRecord,
  SHCategoryStrengthAndWeakness,
  SHStudentEngagementRecord,
  SHStudentPerformanceRecord,
  SHStudentAssignmentPerformanceRecord,
  SHStudentProfileSummaryRecord,
  SHStudentAssignmentViewSummaryResponse,
  SHUser,
  SHAssignmentViewDetailsStudentPerformanceRecord,
  SHStudentAssignment,
  SHStudentAssignmentEngagementRecord,
  SHStudentListResponse,
  SHCourseAggregate,
  SHAssignmentViewDetailsStudentEngagementResponse,
  SHEducationAndEmpathyRecord,
  SHCategoryCount,
  SHOpportunitiesStudentsParams,
  SHOpportunitiesStudents,
  SHCarePlanRecord,
  SHSubCarePlanPayload,
  SHCPRASummary,
  SHClassDistribution,
  SHStudentBelowProficient,
  SHClassConstructSummary,
  SHCPRATableResponse,
  SHCJMMOverallRecord,
  SHCJMMTableRecord,
  SHCJMMCardRecord,
  SHCJMMCardDetailsRecord
} from 'reports/sh/models';

export const getAllShadowHealthCourses = (userId: number): Promise<{ data: { data: Array<ShadowHealthCourse>; fallbackMessage: string } }> => {
  if (userId) {
    return perFacadeApi.get(`/shadowhealth/allCourses/byUserId?userId=${userId}`);
  }
  return Promise.resolve({ data: { data: [] as Array<ShadowHealthCourse>, fallbackMessage: null } });
};

export const getShadowHealthCourseSummaryResponse = (
  courseId: number,
  courseSectionId?: number
): Promise<{ data: { data: ShadowHealthCourseSummary; fallbackMessage: string } }> => {
  if (courseId) {
    if (courseSectionId) {
      return perFacadeApi.get(`/shadowhealth/courseSummary/${courseId}?courseSectionId=${courseSectionId}`);
    }
    return perFacadeApi.get(`/shadowhealth/courseSummary/${courseId}`);
  }
  return Promise.resolve({
    data: {
      data: { courseId: 0, courseName: '', studentPerformanceIndexPercentage: 0, totalAssignmentCount: 0, totalStudentCount: 0 } as ShadowHealthCourseSummary,
      fallbackMessage: null
    }
  });
};

export const getShadowHealthAssignmentSummary = (
  assignmentId: number,
  courseSectionId?: number,
  contentType?: string
): Promise<{ data: { data: Array<SHAssignmentSummaryRecord>; fallbackMessage: string } }> => {
  if (assignmentId) {
    if (courseSectionId) {
      return perFacadeApi.get(`/shadowhealth/assignmentSummary/${assignmentId}?courseSectionId=${courseSectionId}&contentType=${contentType}`);
    }
    return perFacadeApi.get(`/shadowhealth/assignmentSummary/${assignmentId}?contentType=${contentType}`);
  }
  return Promise.resolve({
    data: {
      data: [] as Array<SHAssignmentSummaryRecord>,
      fallbackMessage: null
    }
  });
};

export const buildCourseDropdownOptions = (courses: Array<ShadowHealthCourse>): Array<DropdownOption> => {
  const data = [{ name: '', value: '-1', hidden: true }];
  courses.forEach(course => {
    data.push({ name: course.courseName, value: `${course.courseId}`, hidden: false });
  });
  return data;
};

export const buildSectionDropdownOptions = (sections: Array<ShadowHealthCourseSection>): Array<DropdownOption> => {
  const data = [{ name: '', value: '-1', hidden: true }];
  sections.forEach(({ sectionName, sectionId = 0 }) => {
    data.push({ name: sectionName, value: `${sectionId}`, hidden: false });
  });
  return data;
};

export const getStudentPerformanceReports = (
  courseId: number,
  courseSectionId?: number
): Promise<{ data: { data: Array<SHStudentPerformanceRecord>; fallbackMessage: string } }> => {
  if (courseId) {
    if (courseSectionId) {
      return perFacadeApi.get(`/shadowhealth/studentPerformance/${courseId}?courseSectionId=${courseSectionId}`);
    }
    return perFacadeApi.get(`/shadowhealth/studentPerformance/${courseId}`);
  }
  return Promise.resolve({ data: { data: [] as Array<SHStudentPerformanceRecord>, fallbackMessage: null } });
};

export const getCategoryStrengthsAndWeaknesses = <T>(
  courseId: number,
  categories: string[],
  courseSectionId?: number
): Promise<{ data: { data: SHCategoryStrengthAndWeakness<T>; fallbackMessage: string } }> => {
  if (courseId) {
    if (courseSectionId) {
      return perFacadeApi.get(`/shadowhealth/categoryStrengthAndWeakness/${courseId}?courseSectionId=${courseSectionId}&categoryTypePickList=${categories.join(',')}`);
    }
    return perFacadeApi.get(`/shadowhealth/categoryStrengthAndWeakness/${courseId}?categoryTypePickList=${categories.join(',')}`);
  }
  return Promise.resolve({ data: { data: null, fallbackMessage: null } });
};

export const getStudentEngagementReports = (courseId: number, courseSectionId?: number): Promise<{ data: { data: Array<SHStudentEngagementRecord>; fallbackMessage: string } }> => {
  if (courseId) {
    if (courseSectionId) {
      return perFacadeApi.get(`/shadowhealth/studentEngagement/${courseId}?courseSectionId=${courseSectionId}`);
    }
    return perFacadeApi.get(`/shadowhealth/studentEngagement/${courseId}`);
  }
  return Promise.resolve({ data: { data: [] as Array<SHStudentEngagementRecord>, fallbackMessage: null } });
};

export const getStudentAssignmentPerformanceReports = (
  studentId: number,
  courseId: number,
  courseSectionId?: number
): Promise<{ data: { data: Array<SHStudentAssignmentPerformanceRecord>; fallbackMessage: string } }> => {
  if (studentId && courseId) {
    const baseUrl = `/shadowhealth/student/${studentId}/course/${courseId}/performance`;
    const params = new URLSearchParams();
    if (courseSectionId) {
      params.set('courseSectionId', courseSectionId.toString());
    }
    return perFacadeApi.get(`${baseUrl}?${params.toString()}`);
  }
  return Promise.resolve({ data: { data: [] as Array<SHStudentAssignmentPerformanceRecord>, fallbackMessage: null } });
};

export const getStudentProfileSummaryReports = (studentId: number, courseId?: number): Promise<{ data: { data: SHStudentProfileSummaryRecord[]; fallbackMessage: string } }> => {
  if (studentId) {
    const baseUrl = `/shadowhealth/studentSummary/${studentId}`;
    const params = new URLSearchParams();
    if (courseId) {
      params.set('courseId', courseId.toString());
    }
    return perFacadeApi.get(`${baseUrl}?${params.toString()}`);
  }
  return Promise.resolve({ data: { data: [] as SHStudentProfileSummaryRecord[], fallbackMessage: null } });
};

export const getStudentAssignmentViewSummaryReports = (
  assignmentId: number,
  courseId?: number,
  courseSectionId?: number
): Promise<{ data: { data: SHStudentAssignmentViewSummaryResponse[]; fallbackMessage: string } }> => {
  if (assignmentId) {
    const baseUrl = `/shadowhealth/assignmentViewSummary/${assignmentId}`;
    const params = new URLSearchParams();
    if (courseId) {
      params.set('courseId', courseId.toString());
    }
    if (courseSectionId) {
      params.set('courseSectionId', courseSectionId.toString());
    }
    return perFacadeApi.get(`${baseUrl}?${params.toString()}`);
  }
  return Promise.resolve({ data: { data: [] as SHStudentAssignmentViewSummaryResponse[], fallbackMessage: null } });
};

export const getSHUsersFromEvolveUsername = (evolveUsername: string): Promise<{ data: { data: SHUser[] } }> => {
  if (evolveUsername) {
    return perFacadeApi.get(`/shadowhealth/evolveuser/${evolveUsername}`);
  }
  return Promise.resolve({ data: { data: [] as SHUser[] } });
};

export const getAssignmentDetailStudentPerformanceReports = (
  assignmentId: number,
  courseId?: number,
  courseSectionId?: number
): Promise<{ data: { data: Array<SHAssignmentViewDetailsStudentPerformanceRecord>; fallbackMessage: string } }> => {
  if (assignmentId) {
    const baseUrl = `/shadowhealth/assignment/${assignmentId}/performance`;
    const params = new URLSearchParams();
    if (courseId) {
      params.set('courseId', courseId.toString());
    }
    if (courseSectionId) {
      params.set('courseSectionId', courseSectionId.toString());
    }
    return perFacadeApi.get(`${baseUrl}?${params.toString()}`);
  }
  return Promise.resolve({ data: { data: [] as Array<SHAssignmentViewDetailsStudentPerformanceRecord>, fallbackMessage: null } });
};

export const getAssignmentDetailStudentEngagementReports = (
  assignmentId: number,
  courseSectionId?: number
): Promise<{ data: { data: Array<SHAssignmentViewDetailsStudentEngagementResponse>; fallbackMessage: string } }> => {
  if (!assignmentId) {
    return Promise.resolve({ data: { data: [], fallbackMessage: null } });
  }

  const baseUrl = `/shadowhealth/assignment/${assignmentId}/engagement`;
  const params = new URLSearchParams();

  if (courseSectionId) {
    params.set('courseSectionId', courseSectionId.toString());
  }

  return perFacadeApi.get(`${baseUrl}?${params.toString()}`);
};

export const getAllAssignments = (courseId: number, courseSectionId?: number): Promise<{ data: { data: Array<SHStudentAssignment>; fallbackMessage: string } }> => {
  if (courseId) {
    if (courseSectionId) {
      return perFacadeApi.get(`/shadowhealth/allAssignments/course/${courseId}?courseSectionId=${courseSectionId}`);
    }
    return perFacadeApi.get(`/shadowhealth/allAssignments/course/${courseId}`);
  }
  return Promise.resolve({ data: { data: [] as Array<SHStudentAssignment>, fallbackMessage: null } });
};

export const getStudentAssignmentEngagementReports = (
  studentId: number,
  courseId: number,
  courseSectionId?: number
): Promise<{ data: { data: Array<SHStudentAssignmentEngagementRecord>; fallbackMessage: string } }> => {
  if (studentId && courseId) {
    const baseUrl = `/shadowhealth/student/${studentId}/course/${courseId}/engagement`;
    const params = new URLSearchParams();
    if (courseSectionId) {
      params.set('courseSectionId', courseSectionId.toString());
    }
    return perFacadeApi.get(`${baseUrl}?${params.toString()}`);
  }
  return Promise.resolve({ data: { data: [] as Array<SHStudentAssignmentEngagementRecord>, fallbackMessage: null } });
};

export const getAllSHStudentsByCourseId = (courseId: number, courseSectionId?: number): Promise<{ data: { data: SHStudentListResponse; fallbackMessage: string } }> => {
  if (courseId) {
    const baseUrl = `/shadowhealth/allStudents/${courseId}`;
    const params = new URLSearchParams();
    if (courseSectionId) {
      params.set('courseSectionId', courseSectionId.toString());
    }
    return perFacadeApi.get(`${baseUrl}?${params.toString()}`);
  }
  return Promise.resolve({ data: { data: {} as SHStudentListResponse, fallbackMessage: null } });
};

export const getSHCourseAggregateReports = (instructorId: number): Promise<{ data: { data: SHCourseAggregate[]; fallbackMessage: string } }> => {
  if (instructorId) {
    return perFacadeApi.get(`/shadowhealth/courseAggregates/${instructorId}`);
  }
  return Promise.resolve({ data: { data: [] as Array<SHCourseAggregate>, fallbackMessage: null } });
};

export const getEducationOpportunities = (courseId: number, courseSectionId?: number): Promise<{ data: { data: Array<SHEducationAndEmpathyRecord>; fallbackMessage: string } }> => {
  if (courseId) {
    const baseUrl = `/shadowhealth/educationOpportunities/${courseId}`;
    const params = new URLSearchParams();
    if (courseSectionId) {
      params.set('courseSectionId', courseSectionId.toString());
    }
    return perFacadeApi.get(`${baseUrl}?${params.toString()}`);
  }
  return Promise.resolve({ data: { data: [] as Array<SHEducationAndEmpathyRecord>, fallbackMessage: null } });
};

export const getEmpathyOpportunities = (courseId: number, courseSectionId?: number): Promise<{ data: { data: Array<SHEducationAndEmpathyRecord>; fallbackMessage: string } }> => {
  if (courseId) {
    const baseUrl = `/shadowhealth/empathyOpportunities/${courseId}`;
    const params = new URLSearchParams();
    if (courseSectionId) {
      params.set('courseSectionId', courseSectionId.toString());
    }
    return perFacadeApi.get(`${baseUrl}?${params.toString()}`);
  }
  return Promise.resolve({ data: { data: [] as Array<SHEducationAndEmpathyRecord>, fallbackMessage: null } });
};

export const getCarePlanRootsReports = (courseId: number, courseSectionId?: number): Promise<{ data: { data: Array<SHCarePlanRecord>; fallbackMessage: string } }> => {
  if (courseId) {
    const baseUrl = `/shadowhealth/carePlanRoots/${courseId}`;
    const params = new URLSearchParams();
    if (courseSectionId) {
      params.set('courseSectionId', courseSectionId.toString());
    }
    return perFacadeApi.get(`${baseUrl}?${params.toString()}`);
  }
  return Promise.resolve({ data: { data: [] as Array<SHCarePlanRecord>, fallbackMessage: null } });
};

export const getCarePlanChildrenReports = (
  courseId: number,
  parentId?: number,
  courseSectionId?: number
): Promise<{ data: { data: Array<SHSubCarePlanPayload>; fallbackMessage: string } }> => {
  if (courseId) {
    const baseUrl = `/shadowhealth/carePlanChildren/${courseId}`;
    const params = new URLSearchParams();
    if (parentId) {
      params.set('parent', parentId.toString());
    }
    if (courseSectionId) {
      params.set('courseSectionId', courseSectionId.toString());
    }
    return perFacadeApi.get(`${baseUrl}?${params.toString()}`);
  }
  return Promise.resolve({ data: { data: [] as Array<SHSubCarePlanPayload>, fallbackMessage: null } });
};

export const getCarePlanCount = (courseId: number, courseSectionId?: number): Promise<{ data: { data: SHCategoryCount; fallbackMessage: string } }> => {
  if (courseId) {
    const baseUrl = `/shadowhealth/carePlanCounts/${courseId}`;
    const params = new URLSearchParams();
    if (courseSectionId) {
      params.set('courseSectionId', courseSectionId.toString());
    }
    return perFacadeApi.get(`${baseUrl}?${params.toString()}`);
  }
  return Promise.resolve({ data: { data: {} as SHCategoryCount, fallbackMessage: null } });
};

export const getOpportunitiesStudentsReports = ({
  isEducation,
  courseId,
  opportunity,
  courseSectionId,
  offset,
  limit,
  sortby,
  desc
}: SHOpportunitiesStudentsParams): Promise<{ data: { data: SHOpportunitiesStudents; fallbackMessage: string } }> => {
  if (courseId) {
    const baseUrl = `/shadowhealth/${isEducation ? 'educationOpportunitiesStudents' : 'empathyOpportunitiesStudents'}/${courseId}`;
    const params = new URLSearchParams({
      ...(courseSectionId && { courseSectionId: courseSectionId.toString() }),
      ...(opportunity && { opportunity }),
      ...(isNumber(offset) && { offset: offset.toString() }),
      ...(limit && { limit: limit.toString() }),
      ...(sortby && { sortby: sortby.toString() }),
      ...(desc && { desc: desc.toString() })
    });
    return perFacadeApi.get(`${baseUrl}?${params.toString()}`);
  }
  return Promise.resolve({ data: { data: {} as SHOpportunitiesStudents, fallbackMessage: null } });
};

export const getEducationEmpathyCounts = (courseId: number, courseSectionId?: number): Promise<{ data: { data: SHCategoryCount; fallbackMessage: string } }> => {
  if (courseId) {
    const baseUrl = `/shadowhealth/educationEmpathyCounts/${courseId}`;
    const params = new URLSearchParams();
    if (courseSectionId) {
      params.set('courseSectionId', courseSectionId.toString());
    }
    return perFacadeApi.get(`${baseUrl}?${params.toString()}`);
  }
  return Promise.resolve({ data: { data: {} as SHCategoryCount, fallbackMessage: null } });
};

export const getCPRASummary = (assignmentId: number): Promise<{ data: { data: SHCPRASummary; fallbackMessage: string } }> => {
  if (assignmentId) {
    const baseUrl = `/shadowhealth/cpra/cpraOverall/cpraSummary/${assignmentId}`;
    return perFacadeApi.get(baseUrl);
  }
  return Promise.resolve({ data: { data: {} as SHCPRASummary, fallbackMessage: null } });
};

export const getCPRAClassDistribution = (assignmentId: number): Promise<{ data: { data: SHClassDistribution; fallbackMessage: string } }> => {
  if (assignmentId) {
    const baseUrl = `/shadowhealth/cpra/cpraOverall/classDistribution/${assignmentId}`;
    return perFacadeApi.get(baseUrl);
  }
  return Promise.resolve({ data: { data: {} as SHClassDistribution, fallbackMessage: null } });
};

export const getCPRAStudentsBelowProficient = (assignmentId: number): Promise<{ data: { data: SHStudentBelowProficient[]; fallbackMessage: string } }> => {
  if (assignmentId) {
    const baseUrl = `/shadowhealth/cpra/cpraOverall/studentsBelowProficient/${assignmentId}`;
    return perFacadeApi.get(baseUrl);
  }
  return Promise.resolve({ data: { data: [], fallbackMessage: null } });
};

export const getCPRAClassConstructSummary = (assignmentId: number): Promise<{ data: { data: SHClassConstructSummary; fallbackMessage: string } }> => {
  if (assignmentId) {
    const baseUrl = `/shadowhealth/cpra/cpraOverall/classConstructSummary/${assignmentId}`;
    return perFacadeApi.get(baseUrl);
  }
  return Promise.resolve({ data: { data: {} as SHClassConstructSummary, fallbackMessage: null } });
};

export const getCPRATable = (assignmentId: number): Promise<{ data: { data: SHCPRATableResponse[]; fallbackMessage: string } }> => {
  if (assignmentId) {
    const baseUrl = `/shadowhealth/cpra/cpraTable/${assignmentId}`;
    return perFacadeApi.get(baseUrl);
  }
  return Promise.resolve({ data: { data: [], fallbackMessage: null } });
};

export const getCJMMOverall = (courseId: number): Promise<{ data: { data: SHCJMMOverallRecord[]; fallbackMessage: string } }> => {
  if (courseId) {
    const baseUrl = `/shadowhealth/cjmmCourseLevel/cjmmOverall/${courseId}`;
    return perFacadeApi.get(baseUrl);
  }
  return Promise.resolve({ data: { data: [], fallbackMessage: null } });
};

export const getCJMMClassAssignmentView = (courseId: number): Promise<{ data: { data: SHCJMMTableRecord[]; fallbackMessage: string } }> => {
  if (courseId) {
    const baseUrl = `/shadowhealth/cjmmCourseLevel/classAssignmentView/${courseId}`;
    return perFacadeApi.get(baseUrl);
  }
  return Promise.resolve({ data: { data: [], fallbackMessage: null } });
};

export const getCJMMAssignmentTableRecords = (courseId: number, constructId: string): Promise<{ data: { data: SHCJMMTableRecord[]; fallbackMessage: string } }> => {
  if (courseId && constructId) {
    const baseUrl = `/shadowhealth/cjmmCourseLevel/classAssignmentView/${courseId}/${constructId}`;
    return perFacadeApi.get(baseUrl);
  }
  return Promise.resolve({ data: { data: [], fallbackMessage: null } });
};

export const getCJMMStudentListView = (courseId: number): Promise<{ data: { data: SHCJMMCardRecord[]; fallbackMessage: string } }> => {
  if (courseId) {
    const baseUrl = `/shadowhealth/cjmmCourseLevel/studentListView/${courseId}`;
    return perFacadeApi.get(baseUrl);
  }
  return Promise.resolve({ data: { data: [], fallbackMessage: null } });
};

export const getCJMMCardDetails = (
  courseId: number,
  constructId?: string,
  offset?: number,
  limit?: number
): Promise<{ data: { data: SHCJMMCardDetailsRecord; fallbackMessage: string } }> => {
  if (courseId) {
    const baseUrl = `/shadowhealth/cjmmCourseLevel/classAssignmentView/${courseId}/students`;
    const params = new URLSearchParams({
      ...(constructId && { construct: constructId }),
      ...(isNumber(offset) && { offset: offset.toString() }),
      ...(limit && { limit: limit.toString() })
    });
    return perFacadeApi.get(`${baseUrl}?${params.toString()}`);
  }
  return Promise.resolve({ data: { data: {} as SHCJMMCardDetailsRecord, fallbackMessage: null } });
};
