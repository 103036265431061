import { TableFilterRowConfig, TableFilterRowType } from 'models';
import { LIKELIHOOD_OF_PASSING_NCLEX_LABEL } from 'reports/had/constants/had.constant';
import { PERFORMANCE_LEVEL_LABELS } from './performanceLevel.constant';

const performanceLevelLabel = 'Performance Level';

export const HAD_STUDENT_FILTER_CONFIG_EXAMS_COUNT_UNDER_850_KEY = 'examsCountUnder850';
export const HAD_STUDENT_FILTER_CONFIG_EXIT_EXAMS_COUNT_UNDER_850_KEY = 'exitExamsCountUnder850';
export const HAD_FILTER_EXIT_SCORE_LABEL = 'HESI Exit Score';
export const hadCohortStudentTableFilterConfig: TableFilterRowConfig[] = [
  {
    key: 'activeForTableFilter',
    field: 'activeForTableFilter',
    label: 'Exclude Inactive Students',
    tagLabel: 'Exclude Inactive Students',
    type: TableFilterRowType.SLIDE_SWITCH
  },
  {
    key: 'hesiScore',
    field: 'hesiScore',
    label: HAD_FILTER_EXIT_SCORE_LABEL,
    tagLabel: HAD_FILTER_EXIT_SCORE_LABEL,
    type: TableFilterRowType.RANGE,
    min: '0',
    max: '1600'
  },
  {
    key: 'performanceCategory',
    field: 'performanceCategory',
    label: performanceLevelLabel,
    tagLabel: performanceLevelLabel,
    type: TableFilterRowType.MULTI_SELECT,
    options: [
      { key: PERFORMANCE_LEVEL_LABELS.atRisk, name: PERFORMANCE_LEVEL_LABELS.atRisk },
      { key: PERFORMANCE_LEVEL_LABELS.belowAcceptable, name: PERFORMANCE_LEVEL_LABELS.belowAcceptable },
      { key: PERFORMANCE_LEVEL_LABELS.acceptable, name: PERFORMANCE_LEVEL_LABELS.acceptable },
      { key: PERFORMANCE_LEVEL_LABELS.recommended, name: PERFORMANCE_LEVEL_LABELS.recommended }
    ]
  },
  {
    key: 'examCount',
    field: 'examCount',
    label: 'Number of Exams Taken',
    tagLabel: '# Exams Taken',
    tagUnit: 'exams',
    type: TableFilterRowType.RANGE,
    min: '0',
    max: 'max'
  },
  {
    key: 'examsCountUnder850',
    field: 'examsCountUnder850',
    label: 'Number of Exams Below 850',
    tagLabel: '# Exams Below 850',
    tagUnit: 'exams',
    type: TableFilterRowType.RANGE,
    min: '0',
    max: 'max'
  },
  {
    key: 'exitExamsCountUnder850',
    field: 'exitExamsCountUnder850',
    label: 'Number of Exit Exams Below 850',
    tagLabel: '# Exit Exams Below 850',
    tagUnit: 'exams',
    type: TableFilterRowType.RANGE,
    min: '0',
    max: 'max'
  },
  {
    key: 'likelyNCLEXPassRate',
    field: 'likelyNCLEXPassRate',
    label: LIKELIHOOD_OF_PASSING_NCLEX_LABEL,
    tagLabel: LIKELIHOOD_OF_PASSING_NCLEX_LABEL,
    type: TableFilterRowType.RANGE,
    min: '0',
    max: '100',
    isPercent: true
  }
];

export const cohortListItemsFilterConfig: TableFilterRowConfig[] = [
  {
    key: 'cohortGraduationDate',
    field: 'cohortGraduationDate',
    label: 'Graduation Date',
    tagLabel: 'Graduation Date',
    type: TableFilterRowType.MONTH_RANGE
  },
  {
    key: 'hesiScore',
    field: 'hesiScore',
    label: HAD_FILTER_EXIT_SCORE_LABEL,
    tagLabel: HAD_FILTER_EXIT_SCORE_LABEL,
    type: TableFilterRowType.RANGE,
    min: '0',
    max: '1600'
  },
  {
    key: 'ppnPercentage',
    field: 'ppnPercentage',
    label: 'Likely NCLEX Pass Rate',
    tagLabel: 'Likely NCLEX Pass Rate',
    type: TableFilterRowType.RANGE,
    min: '0',
    max: '100',
    isPercent: true
  },
  {
    key: 'exitExamCount',
    field: 'exitExamCount',
    label: 'Exit Exams Taken',
    tagLabel: 'Exit Exams Taken',
    tagUnit: 'exams',
    type: TableFilterRowType.RANGE,
    min: '0',
    max: 'max'
  },
  {
    key: 'specialityExamCount',
    field: 'specialityExamCount',
    label: 'Specialty Exams Taken',
    tagLabel: '# Specialty Exams Taken',
    tagUnit: 'exams',
    type: TableFilterRowType.RANGE,
    min: '0',
    max: 'max'
  }
];

export const hadExamStudentTableFilterConfig: TableFilterRowConfig[] = [
  {
    key: 'examScore',
    field: 'examScore',
    label: 'HESI Score',
    tagLabel: 'HESI Score',
    type: TableFilterRowType.RANGE,
    min: '0',
    max: '1600'
  },
  {
    key: 'performanceLevel',
    field: 'performanceLevel',
    label: performanceLevelLabel,
    tagLabel: performanceLevelLabel,
    type: TableFilterRowType.MULTI_SELECT,
    options: [
      { key: PERFORMANCE_LEVEL_LABELS.atRisk, name: PERFORMANCE_LEVEL_LABELS.atRisk },
      { key: PERFORMANCE_LEVEL_LABELS.belowAcceptable, name: PERFORMANCE_LEVEL_LABELS.belowAcceptable },
      { key: PERFORMANCE_LEVEL_LABELS.acceptable, name: PERFORMANCE_LEVEL_LABELS.acceptable },
      { key: PERFORMANCE_LEVEL_LABELS.recommended, name: PERFORMANCE_LEVEL_LABELS.recommended }
    ]
  },
  {
    key: 'dateTaken',
    field: 'dateTaken',
    label: 'Date Taken',
    tagLabel: 'Date Taken',
    type: TableFilterRowType.DATE_RANGE
  },
  {
    key: 'conversionScore',
    field: 'conversionScore',
    label: 'Conversion Score',
    tagLabel: 'Conversion Score',
    type: TableFilterRowType.RANGE,
    min: '0',
    max: '100'
  }
];
