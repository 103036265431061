import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { ELSCommonUIConstants, ELSStorageHelper } from '@els/els-ui-common-react';
import { AppConstant } from 'constants/app.constant';
import { cwReducer } from 'reports/cw/redux/ducks/cw';
import { courseActions, coursesReducer } from 'reports/cw/redux/ducks/courses';
import { ehrActions, ehrReducer } from 'reports/ehr/redux/ducks/ehr';
import { hadActions, hadReducer } from 'reports/had/redux/ducks/had';
import { shActions, shReducer } from 'reports/sh/redux/ducks/sh';
import { psActions, psReducer } from 'reports/ps/redux/ducks/ps';
import { hesiNgActions, hesiNgReducer } from 'reports/hesi-ng/redux/ducks/hesi-ng';
import { appActions, appReducer } from './ducks/app';
import { routeReducer } from './ducks/route';
import { languageReducer } from './ducks/language';
import { filterActions, filterReducer } from './ducks/filter';

const restoreState = store => {
  const rootStateFromStorage = ELSStorageHelper.driver(ELSCommonUIConstants.security.Locker.SecondaryDriver)
    .namespace('')
    .get(AppConstant.PERSIST_KEY);

  if (!rootStateFromStorage) {
    return;
  }

  const appState = rootStateFromStorage[AppConstant.reduxResources.APP_STATE];
  const hadState = rootStateFromStorage[AppConstant.reduxResources.HAD_STATE];
  const ehrState = rootStateFromStorage[AppConstant.reduxResources.EHR_STATE];
  const courseState = rootStateFromStorage[AppConstant.reduxResources.COURSES_STATE];
  const filterState = rootStateFromStorage[AppConstant.reduxResources.FILTER_STATE];
  const shState = rootStateFromStorage[AppConstant.reduxResources.SH_STATE];
  const psState = rootStateFromStorage[AppConstant.reduxResources.PS_STATE];
  const hesiNGState = rootStateFromStorage[AppConstant.reduxResources.HESI_NG_STATE];

  if (appState) {
    store.dispatch(appActions.restoreState(appState));
    store.dispatch(hadActions.restoreState(hadState));
    store.dispatch(ehrActions.restoreState(ehrState));
    store.dispatch(courseActions.restoreState(courseState));
    store.dispatch(filterActions.restoreState(filterState));
    store.dispatch(shActions.restoreState(shState));
    store.dispatch(psActions.restoreState(psState));
    store.dispatch(hesiNgActions.restoreState(hesiNGState));
  }
};

const createDefaultReducers = history =>
  combineReducers({
    ...routeReducer,
    [AppConstant.reduxResources.ROUTER]: connectRouter(history),
    [AppConstant.reduxResources.APP_STATE]: appReducer,
    [AppConstant.reduxResources.FILTER_STATE]: filterReducer,
    [AppConstant.reduxResources.CW_STATE]: cwReducer,
    [AppConstant.reduxResources.COURSES_STATE]: coursesReducer,
    [AppConstant.reduxResources.LANGUAGE_STATE]: languageReducer,
    [AppConstant.reduxResources.HAD_STATE]: hadReducer,
    [AppConstant.reduxResources.EHR_STATE]: ehrReducer,
    [AppConstant.reduxResources.SH_STATE]: shReducer,
    [AppConstant.reduxResources.PS_STATE]: psReducer,
    [AppConstant.reduxResources.HESI_NG_STATE]: hesiNgReducer
  });

const actionTypesWhitelist = [
  '@@router/LOCATION_CHANGE',
  'APP/SET_EVOLVE_USER_LOADING',
  'APP/SET_SELECTED_COURSE',
  'HESI/SET_PROGRAM_ID',
  'FILTER/RESET_STATE',
  'FILTER/SET_HAD_COHORT_STUDENT_TABLE_ROWS_STATE',
  'FILTER/SET_HAD_COHORT_STUDENT_TABLE_SEARCH',
  'HAD/SET_CATEGORY_GROUP_SELECTED_KEY',
  'SH/SET_STUDENT_AVERAGE_PERFORMANCE_TYPE',
  'PS/SET_SELECTED_EXAM_MIXED_ID',
  'PS/SET_SELECTED_PROGRAM_ID'
];
const storageMiddleware = ({ getState }) => next => action => {
  const result = next(action);
  if (actionTypesWhitelist.includes(action.type)) {
    ELSStorageHelper.driver(ELSCommonUIConstants.security.Locker.SecondaryDriver)
      .namespace('')
      .put(AppConstant.PERSIST_KEY, getState());
  }
  return result;
};

const configureStore = history => {
  const middlewares = [thunkMiddleware, routerMiddleware(history), storageMiddleware];

  if (process.env.NODE_ENV === 'development') {
    middlewares.push(createLogger({ collapsed: true }));
  }

  const enhancers = [applyMiddleware(...middlewares)];
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(createDefaultReducers(history), {}, composeEnhancers(...enhancers));
  restoreState(store);

  return store;
};

export { configureStore };
