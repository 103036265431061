import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useState } from 'react';

import { ANALYTIC_FEATURES, RoutePath } from 'constants/app.constant';
import { APP_LINK_PAGE_NAME_WITH_ROUTE } from 'constants/externalApp.constant';
import { appActions, appSelectors } from 'redux/ducks/app';
import { AppLinkOutPostBody } from 'models/app-linking';
import { checkPathMatch } from 'helpers/app.helper';
import { trackAAFeature } from 'services/adobe-analytics.service';

import { PerformancePath } from 'reports/cw/constants/cw.constant';
import { Program, CourseSectionInfo } from 'reports/had/models';
import { PS_PATHS } from 'reports/ps/constants';
import { SH_PATHS } from 'reports/sh/constants/sh.constant';
import { shSelectors } from 'reports/sh/redux/ducks/sh';

import useMapEaqTopicToHesiExamByCourseId from '../../../hooks/ui/useMapEaqTopicToHesiExamByCourseId';

interface UseBentoBoxModalProps {
  courses: CourseSectionInfo[];
  hadPrograms: Program[];
  isHesiReadinessApp: boolean;
  handleCourseSelectionModal: Function;
  instructorId?: number;
  onClickOutSide?: Function;
  eaqTopicMappedHesiExamByISBN?: [];
}

const useBentoBoxModal = ({ handleCourseSelectionModal, isHesiReadinessApp, courses, onClickOutSide }: UseBentoBoxModalProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isEHRModalShowing, setEHRModalShowing] = useState(false);
  const isCWBentoBoxLinkShow: boolean = useSelector(appSelectors.getIsCWBentoBoxLinkShow);
  const isCWBentoBoxLinkLoading: boolean = useSelector(appSelectors.getIsCWBentoBoxLinkLoading);
  const appLinkOutPostBody: AppLinkOutPostBody = useSelector(appSelectors.getAppLinkOutPostBody);
  const isEhrReportingEnabled: boolean = useSelector(appSelectors.getIsEhrReportingEnabled);
  const isNclexReportingEnabled: boolean = useSelector(appSelectors.getIsNclexReportingEnabled);
  const isShEnabled: boolean = useSelector(appSelectors.getIsShEnabled);
  const eaqTopicMappedHesiExamByISBN: {} = useSelector(appSelectors.getEaqTopicMappedHesiExamByISBN);
  const allSHCourses = useSelector(shSelectors.getCourses);

  const courseSectionsByReport = useMapEaqTopicToHesiExamByCourseId({ courses, eaqTopicMappedHesiExamByISBN, isEHRModalShowing });

  const isEHRDisable = !isEhrReportingEnabled;
  const isHADDisable = !isNclexReportingEnabled;
  const isCWDisable = !isCWBentoBoxLinkShow;
  const isPRDDisable = !isShEnabled;

  const onCWNavigation = () => {
    trackAAFeature(ANALYTIC_FEATURES.BENTO_BOX_CW_LINK_CLICK);
    if (checkPathMatch([PerformancePath.courseAggregate])) {
      window.location.reload();
      return;
    }
    history.push(PerformancePath.courseAggregate);
  };

  const onEHRNavigation = () => {
    trackAAFeature(ANALYTIC_FEATURES.BENTO_BOX_EHR_LINK_CLICK);
    setEHRModalShowing(true);
    handleCourseSelectionModal();
  };

  const onHADNavigation = () => {
    trackAAFeature(ANALYTIC_FEATURES.BENTO_BOX_HAD_LINK_CLICK);
    if (checkPathMatch([RoutePath.had])) {
      window.location.reload();
      return;
    }
    const path = RoutePath.had;
    if (!isHADDisable) {
      history.push(path);
    }
  };

  const onPRDNavigation = () => {
    trackAAFeature(ANALYTIC_FEATURES.BENTO_BOX_PRD_LINK_CLICK);
    const { courseAggregate, dashboard } = SH_PATHS;
    const path = allSHCourses.length > 1 ? courseAggregate : dashboard;
    if (checkPathMatch([path])) {
      onClickOutSide();
      return;
    }
    if (!isPRDDisable) {
      history.push(path);
    }
  };

  const onPSNavigation = () => {
    trackAAFeature(ANALYTIC_FEATURES.BENTO_BOX_PS_LINK_CLICK);
    const path = PS_PATHS.homePage;
    history.push(path);
  };

  const handleRedirectPage = () => {
    const { performanceReportPage } = appLinkOutPostBody || {};
    if (performanceReportPage !== APP_LINK_PAGE_NAME_WITH_ROUTE.PERFORMANCE_HESI_READINESS.name) {
      dispatch(appActions.setIsCWSubNavInEHRPageShow(true));
    }
    if (isHesiReadinessApp) {
      history.go(RoutePath.ehr);
    }
    history.push(RoutePath.ehr);
  };

  const onCloseCourseSelectionModal = () => {
    handleCourseSelectionModal();
    setEHRModalShowing(false);
  };

  return {
    isCWDisable,
    isEHRDisable,
    isHADDisable,
    isPRDDisable,
    onEHRNavigation,
    onHADNavigation,
    onCWNavigation,
    onPRDNavigation,
    handleRedirectPage,
    isCWBentoBoxLinkLoading,
    isEHRModalShowing,
    courseSectionsByReport,
    onCloseCourseSelectionModal,
    onPSNavigation
  };
};

export default useBentoBoxModal;
