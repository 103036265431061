import React, { ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { isEqual, orderBy, throttle } from 'lodash';
import cx from 'classnames';
import { ELSIconV2 } from 'components/common';
import useExpandRow from 'components/common/use-expand-row/useExpandRow';
import useHasScroll from 'components/common/use-has-scroll/useHasScroll';
import { onKeyDownHandler } from 'helpers/ui.helper';
import { SORT_DIRECTION, UI_EVENTS } from 'constants/app.constant';
import { scrollToTable as scrollToTableService } from 'services/table.service';
import ExpandAllButton from '../expand-all-button/ExpandAllButton';
import { IconTextAlignment } from '../els-icon/ELSIcon';
import useExpandAllRow, { ExpandableRow } from '../use-expand-all-row/useExpandAllRow';

export interface TableColumn<T> {
  field?: keyof T;
  header?: string | ReactNode;
  headerCssModifier?: string;
  cssModifier?: string;
  customRender?: Function;
  customSort?: Function;
  shouldExpandRow?: (data: T) => boolean;
  align?: string;
  minWidth?: number;
  sortNARecordInBottom?: boolean;
  hidden?: boolean;
  headerPendoClass?: string;
  customHeaderRender?: Function;
  customFirstHeaderRender?: Function;
}

export interface TableProps<T> {
  defaultSortField?: keyof T;
  cssModifier?: string;
  defaultSortDirection?: string;
  recordIdField?: keyof T;
  recordChildrenField?: keyof T;
  rowExpandable?: boolean;
  customRowExpandRender?: Function;
  expandRowCssModifier?: string;
  tableId?: string;
  scrollToTopAfterSort?: boolean;
  getCurrentSortStatus?: Function;
  customSecondHeaderRender?: Function;
  headerIconPosition?: IconTextAlignment;
  isTableExpandedAllEnabled?: boolean;
}

export interface BodyRowProps<T> {
  record: T;
  columns: TableColumn<T>[];
  hasScrollLeft: boolean;
  rowIndex?: number;
  handleExpandRow?: Function;
  isRowExpanded?: Function;
  handleExpandRowByRowState?: Function;
  checkExpandStateById?: Function;
  expandedRowState?: ExpandableRow<T>[];
  rowMinWidth?: number;
  bodyRowGTC?: string;
  recordIdField?: keyof Omit<T, 'isChildren'>;
  rowExpandable?: boolean;
  isLoading?: boolean;
  selectedExpandRowId?: number | string;
}

interface ResponsiveTableProps<T> {
  columns: TableColumn<T>[];
  records: T[];
  tableProps: TableProps<T>;
  rerenderFlag?: unknown; // true => when want to force children re-render
}

const FIRST_COLUMN_INDEX = 0;

const withGenericMemo: <T>(component: T, compareMethod: Function) => T = React.memo;

const arePropsEqual = <T extends unknown>(prevProps: ResponsiveTableProps<T>, nextProps: ResponsiveTableProps<T>): boolean => {
  let result = true;
  if (prevProps.rerenderFlag) {
    result = isEqual(prevProps.rerenderFlag, nextProps.rerenderFlag);
  }
  if (result) {
    result = isEqual(prevProps.records, nextProps.records);
  }
  return result;
};

const DEFAULT_COL_MIN_WIDTH = 150;

const sortRecords = <T extends unknown>(columns: TableColumn<T>[], records: T[], field: keyof T, direction): T[] => {
  const sortColumn = columns.find(column => column.field === field);
  if (!sortColumn) {
    return records;
  }

  const nullItemRecords = sortColumn.sortNARecordInBottom ? records.filter(record => !record[field]) : [];
  const notNullItemRecords = sortColumn.sortNARecordInBottom ? records.filter(record => record[field]) : records;
  let sortedRecords;
  if (sortColumn && sortColumn.customSort) {
    const revertValue = direction === SORT_DIRECTION.DESC ? -1 : 1;
    sortedRecords = [...notNullItemRecords].sort((r1, r2) => revertValue * sortColumn.customSort(r1, r2));
  } else {
    sortedRecords = orderBy([...notNullItemRecords], field, direction);
  }
  return [...sortedRecords, ...nullItemRecords];
};

const getRowMinWidth = <T extends unknown>(columns: TableColumn<T>[]): number =>
  columns.filter(column => !column.hidden).reduce((sum, column) => sum + (column.minWidth || DEFAULT_COL_MIN_WIDTH), 0);

const getFirstColumnMinWidth = <T extends unknown>(firstColumn: TableColumn<T>): number => firstColumn.minWidth || DEFAULT_COL_MIN_WIDTH;

const getHeaderScrollableGTC = <T extends unknown>(columns: TableColumn<T>[]): string =>
  columns
    .slice(1)
    .filter(column => !column.hidden)
    .map(column => `${column.minWidth || DEFAULT_COL_MIN_WIDTH}fr`)
    .join(' ');

const renderBodyRow = <T extends unknown>({
  record,
  columns,
  hasScrollLeft,
  rowIndex,
  handleExpandRow,
  isRowExpanded,
  recordIdField,
  handleExpandRowByRowState,
  checkExpandStateById,
  expandedRowState,
  rowMinWidth = getRowMinWidth(columns),
  bodyRowGTC = `${getFirstColumnMinWidth(columns[FIRST_COLUMN_INDEX])}fr ${getHeaderScrollableGTC(columns)}`,
  rowExpandable = false
}: BodyRowProps<T>): ReactElement => {
  const rowId = record[recordIdField] || rowIndex;
  const shouldExpandArrowRender = (columnIndex: number) => rowExpandable && columnIndex === FIRST_COLUMN_INDEX;
  let finalIsRowExpand = false;

  if (checkExpandStateById && typeof checkExpandStateById === 'function') {
    finalIsRowExpand = checkExpandStateById(rowId, expandedRowState);
  } else if (isRowExpanded && typeof isRowExpanded === 'function') {
    finalIsRowExpand = isRowExpanded(rowId);
  }

  const onExpandClick = (): void => {
    if (handleExpandRowByRowState && typeof handleExpandRowByRowState === 'function') {
      handleExpandRowByRowState(rowId, !finalIsRowExpand, expandedRowState);
    } else if (rowExpandable) {
      handleExpandRow(rowId);
    }
  };

  const renderCell = (column: TableColumn<T>, idx: number) => {
    const { shouldExpandRow } = column || {};
    const shouldExpand = shouldExpandRow && typeof shouldExpandRow === 'function' ? shouldExpandRow(record) : shouldExpandArrowRender(idx);
    if (shouldExpand) {
      return (
        <span
          aria-hidden="true"
          className={cx('c-responsive-table-body-cell--expandable', { 'c-responsive-table-body-cell--expandable-expanded': !finalIsRowExpand })}
          onClick={onExpandClick}
        >
          <ELSIconV2 sprite={finalIsRowExpand ? 'ChevronUp' : 'ChevronDown'} size="xs" id="header-sort-icon" className="u-els-color-secondary u-els-margin-right-1o2" />
          {column.customRender ? column.customRender(record, column) : record[column.field]}
        </span>
      );
    }
    return column.customRender ? column.customRender(record, column) : record[column.field];
  };

  return (
    <div style={{ minWidth: rowMinWidth, gridTemplateColumns: bodyRowGTC }} className="c-responsive-table-body-row">
      {columns.map((column, idx) => {
        const alignClass = column.align ? `u-els-text-align-${column.align}` : '';
        return (
          <div
            key={`body-${rowExpandable ? 'cell-expandable' : 'cell'}-${rowId}-${column.field.toString()}`}
            className={cx(
              'c-els-table__cell c-responsive-table-body-cell',
              {
                'c-responsive-table-body-cell--sticky': !idx,
                'c-responsive-table-body-cell--right-shadow': !idx && hasScrollLeft
              },
              alignClass,
              column.cssModifier
            )}
          >
            {renderCell(column, idx)}
          </div>
        );
      })}
    </div>
  );
};

const BREAD_CRUMB_HEIGHT = 78;

const ResponsiveTable = <T extends unknown>({ columns, records, tableProps }: ResponsiveTableProps<T>): ReactElement => {
  const rowMinWidth = getRowMinWidth(columns);
  const firstColumnMinWidth = getFirstColumnMinWidth(columns[FIRST_COLUMN_INDEX]);
  const headerGTC = `${firstColumnMinWidth}fr ${rowMinWidth - firstColumnMinWidth}fr`;
  const headerScrollableGTC = getHeaderScrollableGTC(columns);
  const bodyRowGTC = `${firstColumnMinWidth}fr ${headerScrollableGTC}`;
  const tableRef = useRef<HTMLDivElement>(null);
  const headerScrollableRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);

  const { hasScrollLeft, hasScrollRight, setHasScroll } = useHasScroll();
  const { isTableExpandedAllEnabled, recordIdField, recordChildrenField } = tableProps;
  const { handleExpandRow, isRowExpanded } = useExpandRow();
  const { expandState, rowState: expandedRowState, checkExpandStateById, changeExpandStateById, handleCollapseAllRows, handleExpandAllRows } = useExpandAllRow(records, tableProps);
  const [sortField, setSortField] = useState<keyof T>(tableProps.defaultSortField);
  const [sortDirection, setSortDirection] = useState<string>(tableProps.defaultSortDirection || SORT_DIRECTION.ASC);
  const [displayedRecords, setDisplayRecords] = useState<T[]>([]);

  const scrollToTable = useCallback((): void => {
    if (tableRef && tableRef.current) {
      scrollToTableService(tableRef.current.offsetTop, tableRef.current.getBoundingClientRect().top < BREAD_CRUMB_HEIGHT ? 'auto' : 'smooth');
    }
  }, []);

  const onHeaderClick = (field: keyof T): void => {
    const { scrollToTopAfterSort = true, getCurrentSortStatus } = tableProps;
    let newDirection;
    if (field === sortField) {
      newDirection = sortDirection === SORT_DIRECTION.ASC ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC;
    } else {
      newDirection = SORT_DIRECTION.ASC;
    }
    setSortDirection(newDirection);
    setSortField(field);
    if (getCurrentSortStatus) {
      getCurrentSortStatus(field, newDirection);
    }
    if (scrollToTopAfterSort) {
      scrollToTable();
    }
  };

  useEffect(() => {
    setDisplayRecords(sortRecords(columns, records, sortField, sortDirection));
  }, [columns, records, sortDirection, sortField]);

  useEffect(() => {
    const onHeaderScroll = throttle((evt): void => {
      bodyRef.current.scrollLeft = headerScrollableRef.current.scrollLeft;
      setHasScroll(evt.target);
    }, 10);

    const onBodyScroll = throttle((evt): void => {
      headerScrollableRef.current.scrollLeft = bodyRef.current.scrollLeft;
      setHasScroll(evt.target);
    }, 10);

    const onDocumentResize = throttle((): void => {
      setHasScroll(bodyRef.current);
    }, 150);

    if (headerScrollableRef && headerScrollableRef.current) {
      headerScrollableRef.current.addEventListener('scroll', onHeaderScroll);
      setHasScroll(headerScrollableRef.current);
    }

    if (bodyRef && bodyRef.current) {
      bodyRef.current.addEventListener('scroll', onBodyScroll);
    }

    let scrollEventType = UI_EVENTS.scrollToTable;
    if (tableProps.tableId) {
      scrollEventType += tableProps.tableId;
    }
    window.addEventListener('resize', onDocumentResize);
    window.addEventListener(scrollEventType, scrollToTable);
    return () => {
      window.removeEventListener('resize', onDocumentResize);
      window.removeEventListener(scrollEventType, scrollToTable);
    };
  }, [scrollToTable, setHasScroll, tableProps.tableId]);

  const renderHeaderCell = (column: TableColumn<T>): ReactElement => {
    const alignClass = column.align ? `u-els-text-align-${column.align}` : '';
    return (
      <div
        key={`header-cell-${column.field.toString()}`}
        className={cx('c-els-table__cell c-els-table__cell--header', { 'c-responsive-table-cell--sort-active': column.field === sortField }, column.headerCssModifier)}
      >
        {column.customHeaderRender ? (
          <div className={alignClass}>{column.customHeaderRender()}</div>
        ) : (
          <>
            {column.customFirstHeaderRender && <div>{column.customFirstHeaderRender()}</div>}
            <div
              role="button"
              tabIndex={0}
              className={cx(alignClass, 'c-els-table__sortable-button')}
              onClick={() => onHeaderClick(column.field)}
              onKeyDown={evt => onKeyDownHandler(evt, () => onHeaderClick(column.field))}
            >
              <span className="c-responsive-table__sort-button-text">{column.header}</span>
              {column.field === sortField && (
                <ELSIconV2
                  sprite={sortDirection === SORT_DIRECTION.ASC ? 'ArrowUp' : 'ArrowDown'}
                  id="header-sort-icon"
                  size="xs"
                  textAlignment={tableProps?.headerIconPosition || 'top'}
                  className={cx('u-els-margin-left-1o4', { 'c-responsive-table-cell--sort-asc': sortDirection === SORT_DIRECTION.ASC })}
                />
              )}
              {column.headerPendoClass && (
                <ELSIconV2
                  sprite="InformationOutlineCircle"
                  id="header-pendo-icon"
                  size="xs"
                  textAlignment="offset"
                  className={`u-cursor-pointer u-els-color-secondary u-els-margin-left-1o2 js-pendo-${column.headerPendoClass}`}
                />
              )}
            </div>
          </>
        )}
      </div>
    );
  };

  const expandAllButtonProps = {
    isExpandAll: expandState,
    handleExpandAll: expandState ? handleCollapseAllRows : handleExpandAllRows,
    hideCondition: !isTableExpandedAllEnabled
  };

  return (
    <>
      <ExpandAllButton {...expandAllButtonProps} />
      <div ref={tableRef} role="table" className={cx('c-responsive-table', { 'c-responsive-table--right-inner-shadow': hasScrollRight }, tableProps.cssModifier)}>
        <div className={cx('c-responsive-table-header')}>
          <div style={{ gridTemplateColumns: headerGTC }} className="c-responsive-table-header-row">
            <div style={{ minWidth: firstColumnMinWidth }}>{renderHeaderCell(columns[FIRST_COLUMN_INDEX])}</div>
            <div
              ref={headerScrollableRef}
              className={cx('c-responsive-table-header-row__scrollable', {
                'c-responsive-table-header--left-inner-shadow': hasScrollLeft && !hasScrollRight,
                'c-responsive-table-header--right-inner-shadow': !hasScrollLeft && hasScrollRight,
                'c-responsive-table-header--left-right-inner-shadow': hasScrollLeft && hasScrollRight
              })}
              style={{ width: `100% - ${firstColumnMinWidth}px` }}
            >
              <div className="u-els-display-grid" style={{ minWidth: rowMinWidth - firstColumnMinWidth, gridTemplateColumns: headerScrollableGTC }}>
                {columns.slice(1).map(column => renderHeaderCell(column))}
              </div>
            </div>
          </div>
          {tableProps.customSecondHeaderRender && tableProps.customSecondHeaderRender()}
        </div>

        <div ref={bodyRef} className="c-responsive-table-body">
          {displayedRecords.map((record, index) => {
            const rowId = (record[recordIdField] || index) as number | string;
            let rowExpandableByRecord;
            const isRowExpandedByRecord = isTableExpandedAllEnabled ? checkExpandStateById(rowId, expandedRowState) : isRowExpanded(rowId);
            const handleExpandRowByRowState = isTableExpandedAllEnabled ? changeExpandStateById : undefined;
            if (expandedRowState?.length > 0) {
              rowExpandableByRecord = ((record[recordChildrenField] as unknown) as ExpandableRow<T>[])?.length > 0;
            }

            return (
              <div
                className={cx('c-responsive-table-body-row-wrapper', {
                  [`c-responsive-table-body-row-wrapper--expand ${tableProps.expandRowCssModifier || ''}`]: isRowExpandedByRecord
                })}
                key={`row-wrapper-${rowId}`}
              >
                {renderBodyRow({
                  record,
                  rowIndex: index,
                  columns,
                  handleExpandRow,
                  isRowExpanded,
                  hasScrollLeft,
                  rowMinWidth,
                  bodyRowGTC,
                  rowExpandable: rowExpandableByRecord || tableProps.rowExpandable,
                  recordIdField,
                  handleExpandRowByRowState,
                  checkExpandStateById,
                  expandedRowState
                })}
                {isRowExpandedByRecord && (
                  <div>{tableProps.customRowExpandRender({ record, index, hasScrollLeft, changeExpandStateById, checkExpandStateById, expandedRowState })}</div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export { sortRecords, renderBodyRow };
export default withGenericMemo(ResponsiveTable, arePropsEqual);
