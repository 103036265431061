import { DropdownOption, MenuOption } from 'models';
import { ALL_MENU_OPTION } from 'constants/app.constant';
import { PERFORMANCE_LEVEL_CONFIGS } from 'constants/performanceLevel.constant';

export const HAD_PATHS = {
  dashboard: '/hesiDashboard',
  studentHome: '/hesiDashboard/student',
  cohortDetail: '/cohortDetail/:cohortId',
  examDetail: '/cohortDetail/:cohortId/examDetail/:examId',
  studentDetail: '/cohortDetail/:cohortId/studentSummary/:evolveUserName',
  selectCourseNGN: '/selectCourseNGN'
};

export const DEFAULT_PROGRAM_ID = 0;

const sortUpIcon = 'sorting-up2';
const sortDownIcon = 'sorting-down';

const dependedOptions: Array<DropdownOption> = [
  { name: 'Low to high', value: 'asc', icon: sortUpIcon },
  { name: 'High to low', value: 'desc', icon: sortDownIcon }
];

export const LIKELY_NCLEX_PASS_RATE_LABEL = 'Likely NCLEX Pass Rate';
export const LIKELIHOOD_OF_PASSING_NCLEX_LABEL = 'Likelihood of Passing NCLEX';

export const HAD_DASHBOARD_SORT_OPTIONS: Array<DropdownOption> = [
  {
    name: 'Graduation Date',
    value: 'cohortGraduationDate',
    dependedOptions: [
      { name: 'Most recent first', value: 'desc', icon: sortDownIcon },
      { name: 'Least recent first', value: 'asc', icon: sortUpIcon }
    ]
  },
  {
    name: 'Last updated',
    value: 'ppnUpdatedDateTime',
    dependedOptions: [
      { name: 'Most recent first', value: 'desc', icon: sortDownIcon },
      { name: 'Least recent first', value: 'asc', icon: sortUpIcon }
    ]
  },
  { name: 'HESI Exit Score', value: 'likelyHESIExitScore', dependedOptions },
  { name: LIKELY_NCLEX_PASS_RATE_LABEL, value: 'ppnPercentage', dependedOptions },
  { name: '# Students below 850', value: 'studentsCountBelow850LHES', dependedOptions }
];
export const EXIT_SCORE_SORT_OPTION_IDX = 2;
export const STUDENTS_BELOW_850_SORT_OPTION_IDX = 4;

export const HAD_COLOR_SCHEME = {
  predictedHESI: 'extended-teal-7',
  predictedHESISecondary: 'extended-teal-0',
  predictedNational: 'n4',
  actualHESI: 'extended-blue-9',
  actualHESISecondary: 'extended-blue-0',
  actualNational: 'n4'
};

export const TABLE_MAX_HEIGHT = '504px';
export const CATEGORY_TABLE_MAX_HEIGHT = '402px';

export const VALUE_NA = 'n/a';
export const DISPLAY_VALUE_NA = 0;

export const LABELS_SCORE_SCALE = Object.freeze({
  AT_RISK: PERFORMANCE_LEVEL_CONFIGS.atRisk.range,
  BELOW_ACCEPTABLE: PERFORMANCE_LEVEL_CONFIGS.belowAcceptable.range,
  ACCEPTABLE: '850+'
});

export const GAP_HAD_COLOR_CLASSNAME = 'u-els-fill-white';

export const COLORS_CLASSNAME_SCORE_SCALE = Object.freeze({
  atRisk: PERFORMANCE_LEVEL_CONFIGS.atRisk.backgroundColorClassName,
  atRiskBackgroundFill: PERFORMANCE_LEVEL_CONFIGS.atRisk.fillColorClassName,
  atRiskStripeStroke: PERFORMANCE_LEVEL_CONFIGS.atRisk.stripeStrokeColorClassName,
  belowAcceptable: PERFORMANCE_LEVEL_CONFIGS.belowAcceptable.backgroundColorClassName,
  belowAcceptableBackgroundFill: PERFORMANCE_LEVEL_CONFIGS.belowAcceptable.fillColorClassName,
  belowAcceptableStripeStroke: PERFORMANCE_LEVEL_CONFIGS.belowAcceptable.stripeStrokeColorClassName,
  acceptable: PERFORMANCE_LEVEL_CONFIGS.acceptable.backgroundColorClassName,
  acceptableBackgroundFill: PERFORMANCE_LEVEL_CONFIGS.acceptable.fillColorClassName,
  acceptableStripeStroke: PERFORMANCE_LEVEL_CONFIGS.acceptable.stripeStrokeColorClassName,
  recommended: PERFORMANCE_LEVEL_CONFIGS.recommended.backgroundColorClassName,
  recommendedBackgroundFill: PERFORMANCE_LEVEL_CONFIGS.recommended.fillColorClassName,
  recommendedStripeStroke: PERFORMANCE_LEVEL_CONFIGS.recommended.stripeStrokeColorClassName,
  spaceColor: GAP_HAD_COLOR_CLASSNAME
});

export const SCORE_SCALE_CONSTANT = Object.freeze({
  MAX_AT_RISK: 750,
  MAX_BELOW_ACCEPTABLE: 850,
  MAX_ACCEPTABLE: 900
});

export const SCORE_SCALE_STACKED_BAR_SPAN = Object.freeze({
  atRisk: 93,
  belowAcceptable: 69,
  acceptable: 93,
  height: 15
});

export const CIRCULAR_PROGRESS_CHART_COLORS = Object.freeze({
  predictedFill: 'u-els-fill-extended-blue-8',
  predictedStroke: 'u-els-stroke-extended-blue-11',
  actualFill: 'u-els-fill-extended-blue-11',
  backgroundFill: 'u-els-fill-extended-blue-0'
});

export const COHORT_LEGEND_COLORS = Object.freeze({
  predictedFill: 'u-els-fill-n7',
  predictedStroke: 'u-els-stroke-n3',
  actualFill: 'u-els-fill-n7'
});

export const EVOLVE_EDUCATION_SITE = 'https://evolve.elsevier.com/education/hesi/hesi-dashboard/';

export const EXAM_CUSTOM_TYPE = 'Custom';
export const EXAM_EXIT_TYPE = 'Exit';
export const EXAM_SPECIALTY_TYPE = 'Specialty';
export const ALL_EXAM_TYPE = 'All';
export const STATE_BASED_TYPE = 'State-Based';

export const getExamTypeOptions = (isDemoMaskData: boolean, isStateBaseTypeEnable?: boolean): MenuOption[] => {
  const options = [
    { key: ALL_EXAM_TYPE, name: 'all exams' },
    { key: EXAM_EXIT_TYPE, name: 'all exit exams' },
    { key: EXAM_SPECIALTY_TYPE, name: 'all specialty exams' }
  ];
  if (isStateBaseTypeEnable) {
    options.push({ key: STATE_BASED_TYPE, name: 'all state-based exams' });
  }
  if (!isDemoMaskData) {
    options.push({ key: EXAM_CUSTOM_TYPE, name: 'all custom exams' });
  }
  return options;
};

export const SEE_ALL_CATEGORIES_LABEL = 'See all Categories';
export const DEFAULT_CATEGORY_GROUP_NAME = 'Client Needs';
export const DEFAULT_STUDENT_HOME_CATEGORY_GROUP_NAME = 'Sub-Specialty';

export const STRENGTHS_AND_WEAKNESSES_BOUNDARY = 850;
export const CATEGORY_RANK_STRENGTHS = 'strengths';
export const CATEGORY_RANK_WEAKNESSES = 'weaknesses';

export const STRENGTHS_AND_WEAKNESSES_OPTIONS: MenuOption[] = [
  { key: ALL_MENU_OPTION, name: 'Strengths and Weaknesses' },
  { key: CATEGORY_RANK_STRENGTHS, name: 'Strengths (Mean Category Score ≥ 850)', displayName: 'Strengths' },
  { key: CATEGORY_RANK_WEAKNESSES, name: 'Weaknesses (Mean Category Score < 850)', displayName: 'Weaknesses' }
];

export const CATEGORY_GROUP_HIDDEN = ['AACN Curriculum* Categories', 'NLNAC Accreditation Categories', 'Remediation Category Performance'];
export const STUDENT_CATEGORY_GROUP = ['Client Needs', 'Nursing Process', 'Sub-Specialty', 'Nursing Concepts', 'Specialty Area'];

export const STANDARD_NATIONAL_SCORE = 850;

export const MIN_DATE_TO_SHOW_NGN_LINK = '2021-01-03'; // YYYY-MM-DD

export const MASK_DATA_SESSION_KEY = 'isMaskUserData';
export const INVALID_DATE = 'Invalid date';

export const HESI_CATEGORY_SCORE_LESS_THAN_THREE =
  'We do not graph subcategory scores based on 3 or fewer items. Results with so few questions are more likely to not properly reflect students’ true ability levels.';

export const ONE_HUNDRED_MILLISECONDS = 100;

export const NGN_RIBBON_TITLE = 'Reporting for NGN Available';

export const PERCENT_CORRECT_LIGHT_COLOR = 'info';
export const PERCENT_CORRECT_DARK_COLOR = 'secondary';

export const SKILL_PRIORITY = {
  'Recognize Cues': 5,
  'Analyze Cues': 10,
  'Prioritize Hypotheses': 15,
  'Generate Solutions': 20,
  'Take Actions': 25,
  'Evaluate Outcomes': 30
};
