import {
  TableFilterRowConfig,
  TableFilterRowsState,
  TableFilterRowState,
  TableFilterRowType,
  StudentHesiNGExamResultFilter,
  HesiNGExamResultFilter,
  HesiNGExamResultDateFilter
} from 'models';
import { FILTER_OPTIONS_EXAM_DATE_RANGE } from 'reports/hesi-ng/constants';
import { getDateRangeFromFilterOption } from 'reports/hesi-ng/helpers';

const buildEmptyRowState = (type: TableFilterRowType): TableFilterRowState => ({
  type,
  hasData: false,
  checkedKeys: [],
  from: 0,
  to: 0,
  isChecked: false
});

const buildEmptyRowsState = (rowsConfig: TableFilterRowConfig[]): TableFilterRowsState => {
  const rowsState = {};
  rowsConfig.forEach(row => {
    rowsState[row.field] = buildEmptyRowState(row.type);
  });
  return rowsState;
};

const buildDefaultHesiNGExamResultFilter = (): HesiNGExamResultFilter => {
  return {
    examGroups: [],
    examType: ''
  };
};

const buildDefaultHesiNGExamResultDateFilter = (): HesiNGExamResultDateFilter => {
  const { fromDateUnix, toDateUnix } = getDateRangeFromFilterOption(FILTER_OPTIONS_EXAM_DATE_RANGE.lastThreeMonths);
  return {
    fromDate: fromDateUnix,
    toDate: toDateUnix,
    selectedDate: FILTER_OPTIONS_EXAM_DATE_RANGE.lastThreeMonths
  };
};

const buildDefaultStudentHesiNGExamResultFilter = (): StudentHesiNGExamResultFilter => {
  return {
    examId: 0,
    studentId: 0,
    examGroupId: 0,
    setParamsFromHesiApp: false
  };
};

export { buildEmptyRowState, buildEmptyRowsState, buildDefaultHesiNGExamResultFilter, buildDefaultStudentHesiNGExamResultFilter, buildDefaultHesiNGExamResultDateFilter };
