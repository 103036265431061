import { PERFORMANCE_LEVEL_LABELS, PERFORMANCE_LEVEL_CONFIGS } from 'constants/performanceLevel.constant';
import { SCORE_SCALE_CONSTANT } from 'reports/had/constants/had.constant';
import { Cohort } from 'reports/had/models';
import { PSSherpathCourse } from 'reports/ps/models';
import { ReportCardData } from '../components/pages/dashboard/ps-dashboard-details/usePSDashboardDetailsHook';

const SEPARATE_CHAR = '.';

export const mergeAndSumByCourseId = (courseList: PSSherpathCourse[]): PSSherpathCourse[] => {
  return courseList.reduce((merged, course) => {
    const existingCourse: PSSherpathCourse = merged.find((item: PSSherpathCourse) => item.courseId === course.courseId);
    if (existingCourse) {
      existingCourse.assignmentAverageScore += course.assignmentAverageScore;
      existingCourse.assignmentBelowClassAverage += course.assignmentBelowClassAverage;
      existingCourse.assignmentsNotStarted += course.assignmentsNotStarted;
      existingCourse.assignmentsInProgress += course.assignmentsInProgress;
      existingCourse.assignmentsCompleted += course.assignmentsCompleted;
      existingCourse.assignmentsPassedDue += course.assignmentsPassedDue;
    } else {
      merged.push(course);
    }
    return merged;
  }, []);
};

export const getMixedIdByStudentExam = ({
  examDate,
  examId,
  cohortId,
  cohortMeanHesiScore
}: {
  examDate: string;
  examId: number;
  cohortId?: number;
  cohortMeanHesiScore: number;
}) => {
  return [examId, examDate, cohortId, cohortMeanHesiScore].join(SEPARATE_CHAR);
};

export const getExamDataByMixedId = (id: string) => {
  const [examId, examDate, cohortId, cohortMeanHesiScore] = (id || '').split(SEPARATE_CHAR);
  return { examId: Number(examId), examDate, cohortId: Number(cohortId), cohortMeanHesiScore: Number(cohortMeanHesiScore) };
};

export const getReportCardsData = ({ activeCWCourses, activeSHCourses, handleCWCardClick, handleSHCardClick, cwPillTittle, shPillTittle }): ReportCardData[] => [
  {
    id: 'build-knowledge-cw',
    footerLinkTitle: `Review My Active Sherpath Courses`,
    onFooterLinkClick: handleCWCardClick,
    pillIconName: 'neuroimagery',
    pillTittle: cwPillTittle,
    mainTitle: `${activeCWCourses} courses`,
    captionText: 'Active Sherpath Courses'
  },
  {
    id: 'apply-clinical-judgement-sh',
    footerLinkTitle: `Review My Active Shadow Health Courses`,
    onFooterLinkClick: handleSHCardClick,
    pillIconName: 'stethoscope',
    pillTittle: shPillTittle,
    mainTitle: `${activeSHCourses} courses`,
    captionText: 'Active Shadow Health Courses'
  }
];

export const buildPerformanceLevelColumnChartDataCohort = (cohorts: Cohort[] = []) => {
  const performanceLevelCount = {
    [PERFORMANCE_LEVEL_LABELS.atRisk]: 0,
    [PERFORMANCE_LEVEL_LABELS.belowAcceptable]: 0,
    [PERFORMANCE_LEVEL_LABELS.acceptable]: 0,
    [PERFORMANCE_LEVEL_LABELS.recommended]: 0
  };
  cohorts.forEach(({ likelyHESIExitScore, meanHesiExitScore }) => {
    if (likelyHESIExitScore) {
      return;
    }
    if (meanHesiExitScore < SCORE_SCALE_CONSTANT.MAX_AT_RISK) {
      performanceLevelCount[PERFORMANCE_LEVEL_LABELS.atRisk] += 1;
    } else if (SCORE_SCALE_CONSTANT.MAX_AT_RISK <= meanHesiExitScore && meanHesiExitScore < SCORE_SCALE_CONSTANT.MAX_BELOW_ACCEPTABLE) {
      performanceLevelCount[PERFORMANCE_LEVEL_LABELS.belowAcceptable] += 1;
    } else if (SCORE_SCALE_CONSTANT.MAX_BELOW_ACCEPTABLE <= meanHesiExitScore && meanHesiExitScore < SCORE_SCALE_CONSTANT.MAX_ACCEPTABLE) {
      performanceLevelCount[PERFORMANCE_LEVEL_LABELS.acceptable] += 1;
    } else if (SCORE_SCALE_CONSTANT.MAX_ACCEPTABLE <= meanHesiExitScore) {
      performanceLevelCount[PERFORMANCE_LEVEL_LABELS.recommended] += 1;
    }
  });

  const rangeLevelBarChartData = Object.values(PERFORMANCE_LEVEL_CONFIGS).map(({ label, range, fillColorClassName, stripeStrokeColorClassName }) => {
    const value = performanceLevelCount[label];
    return {
      name: range,
      subLabel: label,
      value,
      fillColorClassName,
      stripeStrokeColorClassName
    };
  });
  const rangeLevelBarChartConfig = {
    barSize: 65,
    xAxisKey: 'name',
    yAxisKey: 'value',
    isStripeColorUsed: false,
    margin: { top: 30, right: 0, bottom: 0, left: 0 }
  };

  return {
    data: rangeLevelBarChartData,
    config: rangeLevelBarChartConfig
  };
};
