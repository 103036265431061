import moment from 'moment';
import { APP_LINK_PAGE_NAME_WITH_ROUTE, ASSIGNMENT_CREATE_LINK_CONFIGS, EXTERNAL_APP_INFO } from 'constants/externalApp.constant';
import { AccessTokenRequestBody } from 'models/app-linking';
import { CourseSection } from 'reports/cw/models';
import { CS_APP_NAME, EAB_APP_NAME, PageNamePrefixes } from 'constants/app.constant';
import { isCSAppByCourseSection, isEABAppByCourseSection } from 'reports/cw/services/courses.service';
import { getNewTokenForCourseSwitcher } from 'services/app-linking.service';
import { getDateRangeFromFilterOption } from 'reports/hesi-ng/helpers';
import { FILTER_OPTIONS_EXAM_DATE_RANGE, FILTER_OPTIONS_PARAM_FOR_HESI } from 'reports/hesi-ng/constants';

interface GetBreadcrumbBackTitle {
  performanceReportPage: string;
  srcApp: string;
  courseSection: CourseSection;
}

interface GetFilterFromHesiAppProps {
  examTypeId?: string;
  examGroupId?: number | string;
  examGroupIds?: number[];
  studentId?: number | string;
  selectedDate?: string;
  examStartDate?: string;
  examEndDate?: string;
}

interface GetNewEolsTokenParams {
  courseId: number;
  userId: number;
  sessionId: string;
  appId: string;
  isbns: string[];
}

export const getTokenSwitchCourse = async ({ courseId, userId, sessionId, appId, isbns }: GetNewEolsTokenParams) => {
  const tokenBody: AccessTokenRequestBody = {
    userId,
    courseId,
    sessionId,
    appParams: {
      appId,
      isbns,
      authorizedIsbns: isbns
    }
  };
  const { data = { token: '' } } = await getNewTokenForCourseSwitcher(tokenBody);
  return data.token as string;
};

export const getBreadcrumbBackTitle = ({ performanceReportPage, srcApp, courseSection }: GetBreadcrumbBackTitle): string => {
  if (performanceReportPage || srcApp) {
    const { REPORTING_PORTAL, PS_DASHBOARD } = APP_LINK_PAGE_NAME_WITH_ROUTE;
    const { SHERPATH, SHER_EVOL, SHADOW_HEALTH, PERFORMANCE } = EXTERNAL_APP_INFO.APP_LINKING;
    if (performanceReportPage === REPORTING_PORTAL.name) {
      return PageNamePrefixes.erp;
    }
    if (performanceReportPage === PS_DASHBOARD.name) {
      return PageNamePrefixes.ps;
    }
    if (srcApp === SHADOW_HEALTH.APP_NAME) {
      return SHADOW_HEALTH.backTitle;
    }
    if (srcApp === SHERPATH.APP_NAME || srcApp === SHER_EVOL.APP_NAME || PERFORMANCE.APP_NAME) {
      const isEABApp = isEABAppByCourseSection(courseSection);
      if (isEABApp) {
        return EAB_APP_NAME;
      }
      const isCSApp = isCSAppByCourseSection(courseSection);
      if (isCSApp) {
        return CS_APP_NAME;
      }
      return SHERPATH.backTitle;
    }
  }
  return '';
};

export const getSelectedExamGroups = (examGroupId: string | number, examGroupIds?: number[]) => {
  const { ALL_GROUPS, MULTIPLE_GROUPS } = FILTER_OPTIONS_PARAM_FOR_HESI;
  if (typeof examGroupId === 'string') {
    if (examGroupId === ALL_GROUPS) {
      return [];
    }
    if (examGroupId === MULTIPLE_GROUPS) {
      return examGroupIds?.map(egi => egi.toString());
    }
    return [examGroupId];
  }
  if (typeof examGroupId === 'number') {
    return [examGroupId?.toString()];
  }
  return [];
};

export const getFilterFromHesiApp = ({ examTypeId, examGroupId, examGroupIds, studentId }: GetFilterFromHesiAppProps) => {
  const selectedExamGroups = getSelectedExamGroups(examGroupId, examGroupIds);
  return {
    examType: examTypeId,
    examGroups: selectedExamGroups,
    setParamsFromHesiApp: true,
    studentId
  };
};

export const getDateFilterFromHesiApp = ({ selectedDate, examEndDate, examStartDate }: GetFilterFromHesiAppProps) => {
  let selectedStartDate;
  let selectedEndDate;
  let selectedDateRange = selectedDate;
  const { CHOOSE_SPECIFIC_DATE_RANGE } = FILTER_OPTIONS_PARAM_FOR_HESI;
  const { chooseSpecificDateRange, lastThreeMonths } = FILTER_OPTIONS_EXAM_DATE_RANGE;
  if (typeof selectedDate === 'string') {
    if (selectedDate === CHOOSE_SPECIFIC_DATE_RANGE) {
      selectedStartDate = moment(examStartDate).valueOf();
      selectedEndDate = moment(examEndDate).valueOf();
    } else {
      const { fromDateUnix, toDateUnix } = getDateRangeFromFilterOption(selectedDate);
      selectedStartDate = fromDateUnix;
      selectedEndDate = toDateUnix;
    }
  } else if (examStartDate && !examEndDate) {
    selectedDateRange = chooseSpecificDateRange;
    selectedStartDate = moment(examStartDate).valueOf();
    selectedEndDate = moment().valueOf();
  } else {
    const { fromDateUnix, toDateUnix } = getDateRangeFromFilterOption(lastThreeMonths);
    selectedStartDate = fromDateUnix;
    selectedEndDate = toDateUnix;
  }
  return {
    selectedDate: selectedDateRange,
    fromDate: selectedStartDate,
    toDate: selectedEndDate
  };
};

export const getEAQStudentStudyReportLinkConfig = isStudentStudyEnabled => {
  if (isStudentStudyEnabled) {
    return {
      appName: EXTERNAL_APP_INFO.APP_LINKING.STUDENT_STUDY.APP_NAME,
      action: EXTERNAL_APP_INFO.APP_LINKING.STUDENT_STUDY.ACTION.PERFORMANCE_VIEW
    };
  }

  return {
    appName: EXTERNAL_APP_INFO.APP_LINKING.EAQ.APP_NAME,
    action: EXTERNAL_APP_INFO.APP_LINKING.EAQ.ACTION.PERFORMANCE_VIEW
  };
};
