import Immutable from 'seamless-immutable';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import {
  buildDefaultHesiNGExamResultFilter,
  buildDefaultStudentHesiNGExamResultFilter,
  buildEmptyRowsState,
  buildDefaultHesiNGExamResultDateFilter
} from 'services/filter.service';
import { AppConstant } from 'constants/app.constant';
import { cohortListItemsFilterConfig, hadCohortStudentTableFilterConfig } from 'constants/filter.constant';
import { ASSIGNMENT_DUE_MENU_SEMESTER } from 'reports/cw/constants/cw.constant';

const baseTableFilter = {
  searchKeyword: '',
  assignmentDueMenuCheckedKey: '',
  assignmentTypeMenuCheckedKeys: [],
  filteredGradedOnly: true
};
const initialState = Immutable({
  hadCohortStudentTableRowsState: buildEmptyRowsState(hadCohortStudentTableFilterConfig),
  hadCohortListItemsState: buildEmptyRowsState(cohortListItemsFilterConfig),
  psCohortListItemsState: buildEmptyRowsState(cohortListItemsFilterConfig),
  hesiNGExamResultFilter: buildDefaultHesiNGExamResultFilter(),
  studentHesiNGExamResultFilter: buildDefaultStudentHesiNGExamResultFilter(),
  hesiNGExamResultDateFilter: buildDefaultHesiNGExamResultDateFilter(),
  hadCohortStudentTableSearch: '',
  cwEngagementAssignmentTypeDropdownId: '',
  cwAssignmentEngagementTableSearch: '',
  cwStudentEngagementTableSearch: '',
  cwGradebookTableSearch: '',
  cwStudentDetailTableSearch: '',
  cwAssignmentSummaryTableFilter: baseTableFilter,
  cwStudentAssignmentSummaryTableFilter: baseTableFilter,
  cwStudentAssignmentEngagementTableFilter: baseTableFilter,
  cwInsStudentDetailAssignmentSummaryFilter: {
    searchKeyword: '',
    assignmentTypeMenuCheckedKeys: [],
    assignmentDueMenuCheckedKey: ASSIGNMENT_DUE_MENU_SEMESTER.key,
    filteredGradedOnly: true
  },
  cwInsStudentDetailAssignmentEngagementFilter: {
    searchKeyword: '',
    assignmentTypeMenuCheckedKeys: [],
    assignmentDueMenuCheckedKey: ASSIGNMENT_DUE_MENU_SEMESTER.key,
    filteredGradedOnly: true
  },
  cwInsStudentDetail: {
    selectedStudentId: '',
    activeTabIndex: 0
  },
  cwAssignmentSummaryTableSearch: ''
});

const resetState = createAction('FILTER/RESET_STATE');
const restoreState = createAction('FILTER/RESTORE_STATE');
const setHADCohortStudentTableRowsState = createAction('FILTER/SET_HAD_COHORT_STUDENT_TABLE_ROWS_STATE');
const setHADCohortListItemsState = createAction('FILTER/SET_HAD_COHORT_LIST_ITEMS_STATE');
const setPSCohortListItemsState = createAction('FILTER/SET_PS_COHORT_LIST_ITEMS_STATE');
const setHesiNGExamResultFilter = createAction('FILTER/SET_HESI_NG_EXAM_RESULT_FILTER');
const setHesiNGExamResultDateFilter = createAction('FILTER/SET_HESI_NG_EXAM_RESULT_DATE_FILTER');
const setStudentHesiNGExamResultFilter = createAction('FILTER/SET_STUDENT_HESI_NG_EXAM_RESULT_FILTER');
const setHADCohortStudentTableSearch = createAction('FILTER/SET_HAD_COHORT_STUDENT_TABLE_SEARCH');
const setCWInsStudentDetail = createAction('FILTER/SET_INS_STUDENT_DETAIL');
const setCWInsStudentDetailAssignmentSummaryFilter = createAction('FILTER/SET_INS_STUDENT_DETAIL_ASSIGNMENT_SUMMARY_FILTER');
const setCWInsStudentDetailAssignmentEngagementFilter = createAction('FILTER/SET_INS_STUDENT_DETAIL_ASSIGNMENT_ENGAGEMENT_FILTER');
const setCWEngagementAssignmentTypeDropdownId = createAction('FILTER/SET_CW_ENGAGEMENT_ASSIGNMENT_TYPE_DROPDOWN_ID');
const setCWAssignmentEngagementTableSearch = createAction('FILTER/SET_CW_ASSIGNMENT_ENGAGEMENT_TABLE_SEARCH');
const setCWStudentEngagementTableSearch = createAction('FILTER/SET_CW_STUDENT_ENGAGEMENT_TABLE_SEARCH');
const setCWGradebookTableSearch = createAction('FILTER/SET_CW_GRADEBOOK_TABLE_SEARCH');
const setCWAssignmentSummaryTableFilter = createAction('FILTER/SET_CW_ASSIGNMENT_SUMMARY_TABLE_FILTER');
const setCWStudentAssignmentSummaryTableFilter = createAction('FILTER/SET_CW_STUDENT_ASSIGNMENT_SUMMARY_TABLE_FILTER');
const setCWStudentAssignmentEngagementTableFilter = createAction('FILTER/SET_CW_STUDENT_ASSIGNMENT_ENGAGEMENT_TABLE_FILTER');
const setCwAssignmentSummaryTableSearch = createAction('FILTER/SET_CW_ASSIGNMENT_SUMMARY_TABLE_SEARCH');

const actions = {
  resetState,
  restoreState,
  setHADCohortStudentTableRowsState,
  setHADCohortListItemsState,
  setPSCohortListItemsState,
  setHesiNGExamResultFilter,
  setHesiNGExamResultDateFilter,
  setStudentHesiNGExamResultFilter,
  setHADCohortStudentTableSearch,
  setCWInsStudentDetail,
  setCWInsStudentDetailAssignmentSummaryFilter,
  setCWInsStudentDetailAssignmentEngagementFilter,
  setCWEngagementAssignmentTypeDropdownId,
  setCWAssignmentEngagementTableSearch,
  setCWStudentEngagementTableSearch,
  setCWGradebookTableSearch,
  setCWAssignmentSummaryTableFilter,
  setCWStudentAssignmentSummaryTableFilter,
  setCWStudentAssignmentEngagementTableFilter,
  setCwAssignmentSummaryTableSearch
};

const reducer = handleActions(
  {
    [actions.resetState]: state => ({ ...initialState, hadCohortListItemsState: state.hadCohortListItemsState, psCohortListItemsState: state.psCohortListItemsState }),
    [actions.restoreState]: (state, { payload }) => state.merge(payload),
    [actions.setHADCohortStudentTableRowsState]: (state, { payload }) => Immutable.set(state, 'hadCohortStudentTableRowsState', payload),
    [actions.setHADCohortListItemsState]: (state, { payload }) => Immutable.set(state, 'hadCohortListItemsState', payload),
    [actions.setPSCohortListItemsState]: (state, { payload }) => Immutable.set(state, 'psCohortListItemsState', payload),
    [actions.setHADCohortStudentTableSearch]: (state, { payload }) => Immutable.set(state, 'hadCohortStudentTableSearch', payload),
    [actions.setCWInsStudentDetail]: (state, { payload }) => Immutable.set(state, 'cwInsStudentDetail', payload),
    [actions.setCWInsStudentDetailAssignmentSummaryFilter]: (state, { payload }) => Immutable.set(state, 'cwInsStudentDetailAssignmentSummaryFilter', payload),
    [actions.setCWInsStudentDetailAssignmentEngagementFilter]: (state, { payload }) => Immutable.set(state, 'cwInsStudentDetailAssignmentEngagementFilter', payload),
    [actions.setCWEngagementAssignmentTypeDropdownId]: (state, { payload }) => Immutable.set(state, 'cwEngagementAssignmentTypeDropdownId', payload),
    [actions.setCWAssignmentEngagementTableSearch]: (state, { payload }) => Immutable.set(state, 'cwAssignmentEngagementTableSearch', payload),
    [actions.setCWStudentEngagementTableSearch]: (state, { payload }) => Immutable.set(state, 'cwStudentEngagementTableSearch', payload),
    [actions.setCWGradebookTableSearch]: (state, { payload }) => Immutable.set(state, 'cwGradebookTableSearch', payload),
    [actions.setCWAssignmentSummaryTableFilter]: (state, { payload }) => Immutable.set(state, 'cwAssignmentSummaryTableFilter', payload),
    [actions.setCWStudentAssignmentSummaryTableFilter]: (state, { payload }) => Immutable.set(state, 'cwStudentAssignmentSummaryTableFilter', payload),
    [actions.setCWStudentAssignmentEngagementTableFilter]: (state, { payload }) => Immutable.set(state, 'cwStudentAssignmentEngagementTableFilter', payload),
    [actions.setCwAssignmentSummaryTableSearch]: (state, { payload }) => Immutable.set(state, 'cwAssignmentSummaryTableSearch', payload),
    [actions.setHesiNGExamResultFilter]: (state, { payload }) => Immutable.set(state, 'hesiNGExamResultFilter', payload),
    [actions.setHesiNGExamResultDateFilter]: (state, { payload }) => Immutable.set(state, 'hesiNGExamResultDateFilter', payload),
    [actions.setStudentHesiNGExamResultFilter]: (state, { payload }) => Immutable.set(state, 'studentHesiNGExamResultFilter', payload)
  },
  initialState
);

const getFilterState = state => state[AppConstant.reduxResources.FILTER_STATE];

const selectors = {
  getHADCohortStudentTableRowsState: createSelector(getFilterState, state => state.hadCohortStudentTableRowsState),
  getHADCohortListItemsState: createSelector(getFilterState, state => state.hadCohortListItemsState),
  getPSCohortListItemsState: createSelector(getFilterState, state => state.psCohortListItemsState),
  getHADCohortStudentTableSearch: createSelector(getFilterState, state => state.hadCohortStudentTableSearch),
  getHesiNGExamResultFilter: createSelector(getFilterState, state => state.hesiNGExamResultFilter),
  getHesiNGExamResultDateFilter: createSelector(getFilterState, state => state.hesiNGExamResultDateFilter),
  getStudentHesiNGExamResultFilter: createSelector(getFilterState, state => state.studentHesiNGExamResultFilter),
  getCWInsStudentDetail: createSelector(getFilterState, state => Immutable.asMutable(state.cwInsStudentDetail || {}, { deep: true })),
  getCWInsStudentDetailAssignmentSummaryFilter: createSelector(getFilterState, state => Immutable.asMutable(state.cwInsStudentDetailAssignmentSummaryFilter || {}, { deep: true })),
  getCWInsStudentDetailAssignmentEngagementFilter: createSelector(getFilterState, state =>
    Immutable.asMutable(state.cwInsStudentDetailAssignmentEngagementFilter || {}, { deep: true })
  ),
  getCWEngagementAssignmentTypeDropdownId: createSelector(getFilterState, state => state.cwEngagementAssignmentTypeDropdownId),
  getCWAssignmentEngagementTableSearch: createSelector(getFilterState, state => state.cwAssignmentEngagementTableSearch),
  getCWStudentEngagementTableSearch: createSelector(getFilterState, state => state.cwStudentEngagementTableSearch),
  getCWAssignmentSummaryTableFilter: createSelector(getFilterState, state => Immutable.asMutable(state.cwAssignmentSummaryTableFilter || {}, { deep: true })),
  getCWStudentAssignmentSummaryTableFilter: createSelector(getFilterState, state => Immutable.asMutable(state.cwStudentAssignmentSummaryTableFilter || {}, { deep: true })),
  getCWStudentAssignmentEngagementTableFilter: createSelector(getFilterState, state => Immutable.asMutable(state.cwStudentAssignmentEngagementTableFilter || {}, { deep: true })),
  getCWGradebookTableSearch: createSelector(getFilterState, state => state.cwGradebookTableSearch),
  getCWStudentDetailTableSearch: createSelector(getFilterState, state => state.cwStudentDetailTableSearch),
  getCWAssignmentSummaryTableSearch: createSelector(getFilterState, state => state.cwAssignmentSummaryTableSearch)
};

export { actions as filterActions, reducer as filterReducer, selectors as filterSelectors };
export { initialState as filterInitialState };
