// ====== Support IE browser ========
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// ==================================

// import './wdyr';
import React from 'react';
import ReactDOM, { render } from 'react-dom';
import App from './components/App';

if (process.env.LOG_A11Y === 'true' && process.env.NODE_ENV !== 'production') {
  import('react-axe').then(axe => {
    axe.default(React, ReactDOM, 1000);
    render(<App />, document.getElementById('app'));
  });
} else {
  render(<App />, document.getElementById('app'));
}
