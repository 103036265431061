import { isEqual } from 'lodash';
import { useState, useMemo, useEffect, useCallback } from 'react';
import { TableProps } from '../responsive-table/ResponsiveTable';

export interface ExpandableRow<T> {
  id: T[keyof T];
  childNodes?: ExpandableRow<T>[];
  isExpanded: boolean;
}

interface UseExpandRowReturnType<T> {
  expandState: boolean;
  rowState: ExpandableRow<T>[];
  handleCollapseAllRows: () => void;
  handleExpandAllRows: () => void;
  changeExpandStateById: <T>(id: T[keyof T], customExpandState: boolean, currentRowState?: ExpandableRow<T>[]) => void;
  checkExpandStateById: <T>(id: T[keyof T], currentRowState: ExpandableRow<T>[]) => boolean;
}

const buildTheRowState = <T>(data: T[], props: TableProps<T>, expandState = false): ExpandableRow<T>[] => {
  const { recordIdField, recordChildrenField } = props;
  return data.map((element: T) => {
    let tempChildNodes: ExpandableRow<T>[] | undefined;
    const elementChildNode = (element[recordChildrenField] as unknown) as T[];
    if (elementChildNode && elementChildNode.length > 0) {
      tempChildNodes = buildTheRowState(elementChildNode, props, expandState);
    }
    return {
      id: element[recordIdField],
      isExpanded: tempChildNodes?.length ? expandState : false,
      childNodes: tempChildNodes
    };
  });
};

const useExpandAllRow = <T>(records: T[], tableProps: TableProps<T>): UseExpandRowReturnType<T> => {
  const [expandState, setExpandState] = useState(false);
  const [rowState, setRowState] = useState<ExpandableRow<T>[]>(() => buildTheRowState(records, tableProps));
  const expandAllRows = useMemo(() => buildTheRowState(records, tableProps, true), [records, tableProps]);
  const collapseAllRows = useMemo(() => buildTheRowState(records, tableProps), [records, tableProps]);

  const handleCollapseAllRows = (): void => {
    setRowState(collapseAllRows);
  };

  const handleExpandAllRows = (): void => {
    setRowState(expandAllRows);
  };

  const changeExpandStateById = useCallback(<T>(id: T[keyof T], customExpandState: boolean, currentRowState: ExpandableRow<T>[] = []): ExpandableRow<T>[] => {
    setExpandState(false);
    const temp = [...currentRowState];
    return [...temp].map(row => {
      if (row.id === id) {
        return {
          ...row,
          isExpanded: customExpandState
        };
      }
      if (row.childNodes) {
        return {
          ...row,
          childNodes: changeExpandStateById(id, customExpandState, row.childNodes)
        };
      }
      return row;
    });
  }, []);

  const handleChangeExpandStateById = useCallback(<T>(id: T[keyof T], customExpandState: boolean): void => setRowState(changeExpandStateById(id, customExpandState, rowState)), [
    changeExpandStateById,
    rowState
  ]);

  const checkExpandStateById = useCallback(<T>(id: T[keyof T], currentRowState: ExpandableRow<T>[] = []): boolean => {
    const temp: ExpandableRow<T>[] = [...currentRowState];
    const foundRow = temp.find(row => row.id === id);
    let isExpanded = false;
    if (foundRow) {
      isExpanded = foundRow.isExpanded;
    } else {
      temp.some(row => {
        if (row.childNodes) {
          isExpanded = checkExpandStateById(id, row.childNodes);
        }
        return isExpanded;
      });
    }
    return isExpanded;
  }, []);

  useEffect(() => {
    if (isEqual(rowState, expandAllRows)) {
      setExpandState(true);
    } else {
      setExpandState(false);
    }
  }, [expandAllRows, rowState]);

  return { expandState, rowState, handleCollapseAllRows, handleExpandAllRows, changeExpandStateById: handleChangeExpandStateById, checkExpandStateById };
};

export default useExpandAllRow;
