export interface UseMapEaqTopicToHesiExamByCourseIdProps {
  courses: [];
  eaqTopicMappedHesiExamByISBN: [];
}

const useMapEaqTopicToHesiExamByCourseId = ({ courses, eaqTopicMappedHesiExamByISBN, isEHRModalShowing }) => {
  if (isEHRModalShowing) {
    return courses.filter(c => {
      return c.entitlements.some(etm => {
        return eaqTopicMappedHesiExamByISBN[etm.isbn]?.length > 0;
      });
    });
  }
  return [];
};

export default useMapEaqTopicToHesiExamByCourseId;
