import { ADOBE_PRODUCT_CODE, FeatureFlagNames, Language, RoutePath, SYSTEM_ID, USER_ROLE } from 'constants/app.constant';
import { Component } from 'react';
import { appSelectors } from 'redux/ducks/app';
import { connect } from 'react-redux';
import { ELSAdobeAnalyticService, ELSCommonConfig, ELSCommonUIConstants } from '@els/els-ui-common-react';
import { routerSelectors } from 'redux/ducks/route';
import { CourseSection } from 'reports/cw/models';
import { courseSelectors } from 'reports/cw/redux/ducks/courses';
import { hadSelectors } from 'reports/had/redux/ducks/had';
import { SH_PATHS } from 'reports/sh/constants/sh.constant';
import { shSelectors } from 'reports/sh/redux/ducks/sh';
import { isFeatureEnabled } from 'services/feature-flag.service';

interface AdobeAnalyticsProps {
  courseSectionInfo: CourseSection;
  evolveUser: string;
  evolveUserLoading: boolean;
  programId: string;
  programName: string;
  userId: string;
  userRole: string;
  instructorId: number;
}

interface AdobeAnalyticsInitState {
  isAdobeAnalyticsRegistered: boolean;
  isPageDataUpdated: boolean;
}

class AdobeAnalyticsInit extends Component<AdobeAnalyticsProps, AdobeAnalyticsInitState> {
  constructor(props: AdobeAnalyticsProps) {
    super(props);
    this.state = {
      isAdobeAnalyticsRegistered: false,
      isPageDataUpdated: false
    };
  }

  componentDidMount() {
    if (!this.state.isAdobeAnalyticsRegistered) {
      this.registerAdobeAnalytics(this.props);
    }
  }

  componentDidUpdate() {
    if (!this.state.isAdobeAnalyticsRegistered) {
      this.registerAdobeAnalytics(this.props);
      return;
    }

    if (this.state.isAdobeAnalyticsRegistered && !this.state.isPageDataUpdated) {
      this.forceUpdatePageData(this.props);
    }
  }

  // ToDo: data manipulation logic here is shared with PendoInit. Move to a parent component
  registerAdobeAnalytics = props => {
    const { courseSectionInfo, evolveUser, evolveUserLoading, featureFlags, programName, programId, userId, userRole, instructorId } = props;
    const courseId = courseSectionInfo?.id?.toString() || '';

    let accountId = courseSectionInfo?.institution?.id?.toString() || '';
    let accountName = courseSectionInfo?.institution?.name || '';
    let prefix = SYSTEM_ID.EOLS;

    if (!userId || !userRole) {
      return;
    }

    const locationsHash = window.location.hash.toLowerCase();

    // if we're on SH, we might not have institution id, so we need to use instructor id
    if (locationsHash === `#${SH_PATHS.dashboard.toLowerCase()}`) {
      accountId = accountId || instructorId?.toString();
      accountName = accountName || 'shadowhealth-user';
    } else {
      // If 'Courseware' or 'Hesi Readiness', we need to wait 'courseSectionInfo + evolveUser' done to register Pendo
      if (locationsHash !== `#${RoutePath.had.toLowerCase()}`) {
        if (!courseId) {
          return;
        }

        if ((featureFlags || []).length > 0) {
          // TODO: comment on the purpose of this flag or remove the flag
          const enabledEvolveIdOnAnalyticsFlag = isFeatureEnabled(featureFlags, FeatureFlagNames.EVOLVE_ID_ON_ANALYTICS_ENABLED, courseId);

          if (enabledEvolveIdOnAnalyticsFlag && evolveUserLoading) {
            return;
          }

          if (enabledEvolveIdOnAnalyticsFlag && !evolveUserLoading && evolveUser) {
            prefix = SYSTEM_ID.EVOLVE;
          }
        }
      }

      // if we're on NCLEX, we might not have institution id, so we need to use program id
      if (locationsHash === `#${RoutePath.had.toLowerCase()}`) {
        accountId = !accountId ? programId.toString() : accountId;
        accountName = !accountName ? programName : accountName;
      }
    }

    if (!accountId || !accountName) {
      return;
    }

    const registrationConfig = {
      accountId: `${prefix}:${accountId}`,
      accountName: `${prefix}:${accountName}`,
      businessUnit: 'els:nh',
      cmsName: '',
      language: Language.DEFAULT_LOCALE,
      productName: ADOBE_PRODUCT_CODE.SHERPATH_COURSEWARE,
      userId: `${prefix}:${userId}`
    };

    // Reset the configs as to not inherit old configs
    // Consider adding this to the internal ELSAdobeAnalyticService.registerAdobeAnalytic method logic
    ELSAdobeAnalyticService.resetService();
    // ELSAdobeAnalyticService calls this url to check whether adobe analytics is enabled
    // Only register Adobe Analytic after logging in because it's required the user token
    const adobeFeatureApiUrl = `${ELSCommonConfig.buildUrl}/api/features/app/${ELSCommonUIConstants.appIds.PERFORMANCE_REPORT}/key/ADOBE_ANALYTICS_DISABLED`;
    ELSAdobeAnalyticService.registerAdobeAnalytic(adobeFeatureApiUrl, registrationConfig);

    this.setState({ isAdobeAnalyticsRegistered: true });
  };

  forceUpdatePageData = props => {
    const { courseSectionInfo, evolveUser, evolveUserLoading, featureFlags, programName, programId, userId, userRole, instructorId } = props;
    const aaData = window?.pageData;
    const aaPage = window?.pageData?.page;
    const aaVisitor = window?.pageData?.visitor;
    const aaEducation = window?.pageData?.education;
    const courseId = courseSectionInfo?.id?.toString() || '';

    let accountId = courseSectionInfo?.institution?.id?.toString() || '';
    let accountName = courseSectionInfo?.institution?.name || '';
    let prefix = SYSTEM_ID.EOLS;

    // do not update if there's are already details and an education object
    if (!userId || !userRole) {
      return;
    }

    const locationsHash = window.location.hash.toLowerCase();

    // if we're on SH, we might not have institution id, so we need to use instructor id
    if (locationsHash === `#${SH_PATHS.dashboard.toLowerCase()}`) {
      accountId = accountId || instructorId.toString();
      accountName = accountName || 'shadowhealth-user';
    } else {
      // If 'Courseware' or 'Hesi Readiness', we need to wait 'courseSectionInfo + evolveUser' done to register Pendo
      if (locationsHash !== `#${RoutePath.had.toLowerCase()}`) {
        if (!courseId) {
          return;
        }

        if ((featureFlags || []).length > 0) {
          // TODO: comment on the purpose of this flag or remove the flag
          const enabledEvolveIdOnAnalyticsFlag = isFeatureEnabled(featureFlags, FeatureFlagNames.EVOLVE_ID_ON_ANALYTICS_ENABLED, courseId);

          if (enabledEvolveIdOnAnalyticsFlag && evolveUserLoading) {
            return;
          }

          if (enabledEvolveIdOnAnalyticsFlag && !evolveUserLoading && evolveUser) {
            prefix = SYSTEM_ID.EVOLVE;
          }
        }
      }

      // if we're on NCLEX, we might not have institution id, so we need to use program id
      if (locationsHash === `#${RoutePath.had.toLowerCase()}`) {
        accountId = !accountId ? programId.toString() : accountId;
        accountName = !accountName ? programName : accountName;
      }
    }

    if (!accountId || !accountName) {
      return;
    }

    const educationData = aaEducation || {};

    if (userRole.toLowerCase() === USER_ROLE.EOLS_INSTRUCTOR.toLowerCase() || userRole.toLowerCase() === USER_ROLE.EVOLVE_INSTRUCTOR.toLowerCase()) {
      Object.assign(educationData, { instructorId: `${prefix}:${userId}` });
    } else if (userRole.toLowerCase() === USER_ROLE.STUDENT.toLowerCase()) {
      Object.assign(educationData, { studentId: `${prefix}:${userId}` });
    }

    const visitorData = {
      ...aaVisitor,
      accountId: `${prefix}:${accountId}`,
      accountName: `${prefix}:${accountName}`,
      userId: `${prefix}:${userId}`,
      details: userRole
    };

    const pageData = {
      ...aaPage,
      language: Language.DEFAULT_LOCALE,
      businessUnit: 'els:nh'
    };

    ELSAdobeAnalyticService.trackEvent(ELSCommonUIConstants.analytics.eventTypes.newPage, {
      ...aaData,
      visitor: visitorData,
      page: pageData,
      education: educationData
    });

    this.setState({ isPageDataUpdated: true });
  };

  render() {
    return null;
  }
}

const mapStateToProps = state => ({
  courseSectionInfo: courseSelectors.getCourseSectionInfo(state),
  isbn: appSelectors.getIsbn(state),
  userId: appSelectors.getUserId(state),
  userRole: appSelectors.getUserRole(state),
  evolveUser: appSelectors.getEvolveUser(state),
  evolveUserLoading: appSelectors.getEvolveUserLoading(state),
  featureFlags: appSelectors.getFeatureFlags(state),
  location: routerSelectors.getLocation(state),
  programId: hadSelectors.getProgramId(state),
  programName: hadSelectors.getProgramName(state),
  instructorId: shSelectors.getInstructorId(state)
});

export default connect(mapStateToProps)(AdobeAnalyticsInit);
