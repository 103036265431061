import moment from 'moment';
import { SORT_DIRECTION, DATE_FORMAT, UI_EVENTS } from 'constants/app.constant';
import { TableFilterRowsState, TableFilterRowType } from 'models';
import { querySelectorWrapper } from './method-wrapper.service';

const filterTableRecords = <T>(records: T[], filters: TableFilterRowsState, searchKeyword?: string, searchField?: string): T[] => {
  let results = records;
  if (searchKeyword) {
    const refinedKeyword = searchKeyword.trim().toLowerCase();
    if (refinedKeyword) {
      results = results.filter(record => record[searchField].toLowerCase().includes(refinedKeyword));
    }
  }

  Object.keys(filters).forEach(key => {
    const filterObj = filters[key];
    const { hasData, type } = filterObj;
    if (hasData) {
      switch (type) {
        case TableFilterRowType.RANGE:
        case TableFilterRowType.DECIMAL_RANGE: {
          if (filterObj.from) {
            results = results.filter(record => record[key] >= filterObj.from);
          }
          if (filterObj.to) {
            results = results.filter(record => record[key] <= filterObj.to);
          }
          break;
        }
        case TableFilterRowType.MULTI_SELECT: {
          if (filterObj.checkedKeys.length) {
            results = results.filter(record => filterObj.checkedKeys.includes(record[key]));
          }
          break;
        }
        case TableFilterRowType.SLIDE_SWITCH: {
          if (filterObj.isChecked) {
            results = results.filter(record => record[key] === filterObj.isChecked);
          }
          break;
        }
        case TableFilterRowType.DATE_RANGE:
        case TableFilterRowType.MONTH_RANGE: {
          if (filterObj.from) {
            results = results.filter(record => moment(record[key]).isAfter(moment(filterObj.from, DATE_FORMAT).subtract(1, 'day')));
          }
          if (filterObj.to) {
            results = results.filter(record => moment(record[key]).isBefore(moment(filterObj.to, DATE_FORMAT).add(1, 'day')));
          }
          break;
        }
        default: {
          break;
        }
      }
    }
  });
  return results;
};

const triggerSortTableManually = (sortDirection: string, columnHeaderSelector: string, tableId?: string) => {
  const activeSortSelector = `.c-responsive-table-cell--sort-active`;
  const sortedButtonSelector = '.c-els-table__sortable-button';
  const ascSortIconSelector = '.c-responsive-table-cell--sort-asc';
  const isColumnSorting = querySelectorWrapper(`${columnHeaderSelector}${activeSortSelector}`) !== null;
  const sortedButtonElm = querySelectorWrapper(`${columnHeaderSelector} ${sortedButtonSelector}`) as HTMLButtonElement;
  if (!sortedButtonElm) {
    return;
  }
  if (isColumnSorting) {
    const isSortingAsc = querySelectorWrapper(`${columnHeaderSelector}${activeSortSelector} ${sortedButtonSelector} ${ascSortIconSelector}`) !== null;
    if (isSortingAsc) {
      if (sortDirection === SORT_DIRECTION.DESC) {
        sortedButtonElm.click();
      } else {
        let customEventType = UI_EVENTS.scrollToTable;
        if (tableId) {
          customEventType += tableId;
        }
        // To make sure others event done
        setTimeout(() => {
          const evt = new CustomEvent(customEventType);
          window.dispatchEvent(evt);
        }, 0);
      }
    } else if (sortDirection === SORT_DIRECTION.ASC) {
      sortedButtonElm.click();
    }
  } else {
    sortedButtonElm.click(); // The first click will be sort ASC
    if (sortDirection === SORT_DIRECTION.DESC) {
      // To make sure the first click done
      setTimeout(() => {
        sortedButtonElm.click();
      }, 0);
    }
  }
};

const scrollToTable = (offsetTableTop: number, behavior: ScrollBehavior = 'smooth') => {
  const pageHeaderSelector = querySelectorWrapper('.c-page-header') as HTMLDivElement;
  scrollTo({ left: 0, top: offsetTableTop - pageHeaderSelector.offsetHeight, behavior });
};

export { filterTableRecords, triggerSortTableManually, scrollToTable };
